import { useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { useUser } from "src/auth"
import Avatar from "src/components/Avatar/Avatar"
import CreateAccountPopup from "src/components/CreateAccountPopup/CreateAccountPopup"
import {
  InviteModal,
  useInviteModal,
} from "src/components/InviteModal/InviteModal"
import {
  GlowAddButton,
  GlowButton,
  GlowCard,
  GlowFlexbox,
  GlowText,
} from "src/glow"
import { usePopup } from "src/hooks/modal"
import useTracking from "src/tracking/useTracking"

import { SettingsPageOverviewHouse_household$key } from "./__generated__/SettingsPageOverviewHouse_household.graphql"

export const SettingsPageOverviewHouse = (props: {
  household: SettingsPageOverviewHouse_household$key
  onGoToAccountView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewHouse_household on Household {
        pendingInvites {
          id
          ...Avatar_invite
        }
        members {
          id
          user {
            ...Avatar_user
          }
        }
        address {
          firstLine
          postcode
          ...CreateAccountPopup_address
        }
        nousholdEmail
      }
    `,
    props.household,
  )

  const track = useTracking()
  const intl = useIntl()
  const user = useUser()
  const inviteModal = useInviteModal()
  const createAccountPopup = usePopup()

  return (
    <>
      <GlowCard
        style={{
          clipPath: "polygon(50% 0%, 100% 15%, 100% 100%, 0% 100%, 0% 15%)",
        }}
      >
        <GlowFlexbox direction="column" gap="4" alignItems="center">
          <GlowFlexbox
            alignItems="center"
            justifyContent="center"
            gap="2"
            margin={{ top: "6" }}
          >
            {household.members?.map(
              (member) =>
                member.user && (
                  <Avatar key={member.id} user={member.user} size={"base"} />
                ),
            )}
            {household.pendingInvites?.map((invite) => (
              <Avatar key={invite.id} invite={invite} size="base" />
            ))}
            <GlowAddButton
              size="sm"
              onClick={() => {
                if (user?.isAnonymous) {
                  return createAccountPopup.open()
                }

                track(["Invite", "Started"])
                inviteModal.onOpen()
              }}
            />
          </GlowFlexbox>
          <GlowText size="2xl" isDisplay>
            {[household.address?.firstLine, household.address?.postcode]
              .filter(Boolean)
              .join(", ")}
          </GlowText>
          {household.nousholdEmail && (
            <GlowText className="text-gray-500/64">
              {household.nousholdEmail}
            </GlowText>
          )}
          <GlowButton
            variant="tertiary"
            label={intl.formatMessage({
              id: "page.settings.view.overview.goToAccountView",
              defaultMessage: "Manage account",
            })}
            className="w-full"
            onClick={props.onGoToAccountView}
          />
        </GlowFlexbox>
      </GlowCard>

      <CreateAccountPopup
        address={household.address ?? null}
        isOpen={createAccountPopup.isOpen}
        onClose={createAccountPopup.close}
      />
      <InviteModal query={inviteModal.query} onClose={inviteModal.onClose} />
    </>
  )
}
