/**
 * @generated SignedSource<<f02869105f8d87abc71a87c3057c8cbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSubPageInboxes_household$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingConnectEmailBenefitsStepContent_household">;
  readonly " $fragmentType": "SettingsPageSubPageInboxes_household";
};
export type SettingsPageSubPageInboxes_household$key = {
  readonly " $data"?: SettingsPageSubPageInboxes_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSubPageInboxes_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageSubPageInboxes_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingConnectEmailBenefitsStepContent_household"
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "8a9afc7de751181f11a1ed13934f2105";

export default node;
