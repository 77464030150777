import { useIntl, FormattedMessage } from "react-intl"

import { Logo } from "src/components/Logo/Logo"
import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowFlexbox, GlowIcon, GlowLayoutCentered, GlowText } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function ConnectEmailPageLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const intl = useIntl()

  const pageTitle = intl.formatMessage({
    defaultMessage: "Home",
    id: "dashboard.page_title",
  })

  const pageDescription = intl.formatMessage({
    defaultMessage: "Home page",
    id: "dashboard.page_description",
  })

  return (
    <div className="radial-gradient min-h-full pb-10">
      <MetaTags title={pageTitle} description={pageDescription} />
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              backgroundColour="nous-glow"
              topComponent={<Logo className="h-5" />}
              showAvatar
              showNotificationsButton
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        <GlowLayoutCentered variant="narrow">
          <div className="mt-12">
            <PageTitle />
          </div>

          {children ?? <LoadingPagePlaceholder />}
        </GlowLayoutCentered>
      </GlowPageLayout>
    </div>
  )
}

function PageTitle() {
  return (
    <GlowFlexbox gap="2" alignItems="center" justifyContent="center">
      <GlowText size="xl">
        <FormattedMessage
          id="connectEmailPage.title"
          defaultMessage="Connected inboxes"
        />
      </GlowText>
      <GlowIcon name="ai" />
    </GlowFlexbox>
  )
}
