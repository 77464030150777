import { MessageDescriptor, defineMessage } from "react-intl"

import { GlowIconName } from "src/glow"

export const notificationContentByTypeRecord: Record<
  string,
  {
    title: MessageDescriptor
    description: MessageDescriptor
    iconName: GlowIconName
  }
> = {
  EnergyDocumentUploadUserNotification: {
    title: defineMessage({
      id: "userNotification.energyUploadDocument.title",
      defaultMessage: "Add a recent energy bill",
    }),
    description: defineMessage({
      id: "userNotification.energyUploadDocument.description",
      defaultMessage: "We need it to create your savings recommendation.",
    }),
    iconName: "touch_finger_1_regular",
  },
  ReviewEnergyRecommendationUserNotification: {
    title: defineMessage({
      id: "userNotification.reviewEnergyRecommendation.title",
      defaultMessage: "Review our recommendation",
    }),
    description: defineMessage({
      id: "userNotification.reviewEnergyRecommendation.description",
      defaultMessage:
        "You have {expiresAfter, plural, one {# day} other {# days}} to review our energy switch recommendation",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  ReviewMobileRecommendationUserNotification: {
    title: defineMessage({
      id: "userNotification.reviewMobileRecommendation.title",
      defaultMessage: "Review our recommendation",
    }),
    description: defineMessage({
      id: "userNotification.reviewMobileRecommendation.description",
      defaultMessage:
        "You have {expiresAfter, plural, one {# day} other {# days}} to review our mobile tariff recommendation",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  ReviewBroadbandRecommendationUserNotification: {
    title: defineMessage({
      id: "userNotification.reviewBroadbandRecommendation.title",
      defaultMessage: "Review our recommendation",
    }),
    description: defineMessage({
      id: "userNotification.reviewBroadbandRecommendation.description",
      defaultMessage:
        "You have {expiresAfter, plural, one {# day} other {# days}} to review our broadband tariff recommendation",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  SubmitBankAccountDetailsUserNotification: {
    title: defineMessage({
      id: "userNotification.submitBankAccountDetails.title",
      defaultMessage: "Submit bank account details",
    }),
    description: defineMessage({
      id: "userNotification.submitBankAccountDetails.description",
      defaultMessage: "We need it to complete your switch.",
    }),
    iconName: "lock_2_regular",
  },
  SavingsStatementCreatedUserNotification: {
    title: defineMessage({
      id: "userNotification.savingsStatementCreated.title",
      defaultMessage: "New statement",
    }),
    description: defineMessage({
      id: "userNotification.savingsStatementCreated.description",
      defaultMessage: "You have a new savings statement.",
    }),
    iconName: "saving_bank_1_regular",
  },
  UpdateHouseholdPreferencesUserNotification: {
    title: defineMessage({
      id: "userNotification.updateHouseholdPreferences.title",
      defaultMessage: "Submit household preferences",
    }),
    description: defineMessage({
      id: "userNotification.updateHouseholdPreferences.description",
      defaultMessage: "We need these to find deals that suit your household.",
    }),
    iconName: "love_it_settings_regular",
  },
  EnergyWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.energyWizard.title",
      defaultMessage: "Tell us about your energy",
    }),
    description: defineMessage({
      id: "userNotification.energyWizard.description",
      defaultMessage: "Find out how much you can save on your energy.",
    }),
    iconName: "flash_1_regular",
  },
  BroadbandWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.broadbandWizard.title",
      defaultMessage: "Tell us about your broadband",
    }),
    description: defineMessage({
      id: "userNotification.broadbandWizard.description",
      defaultMessage: "Find out how much you can save on your broadband.",
    }),
    iconName: "wifi_regular",
  },
  MobileWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.mobileWizard.title",
      defaultMessage: "Tell us about your mobile",
    }),
    description: defineMessage({
      id: "userNotification.mobileWizard.description",
      defaultMessage: "Find out how much you can save on your mobile.",
    }),
    iconName: "mobile_phone_regular",
  },
  MortgageWizardUserNotification: {
    title: defineMessage({
      id: "userNotification.mortgageWizard.title",
      defaultMessage: "Tell us about your mortgage",
    }),
    description: defineMessage({
      id: "userNotification.mortgageWizard.description",
      defaultMessage: "Find out how much you can save on your mortgage.",
    }),
    iconName: "real_estate_insurance_house_regular",
  },
  DelegateManagementOfServicesUserNotification: {
    title: defineMessage({
      id: "userNotification.delegateManagementOfServices.title",
      defaultMessage: "Let us manage your household services",
    }),
    description: defineMessage({
      id: "userNotification.delegateManagementOfServices.description",
      defaultMessage: "We'll make sure you're on the best deals.",
    }),
    iconName: "currency_pound_bubble_regular",
  },
  ConnectEmailUserNotification: {
    title: defineMessage({
      id: "userNotification.connectEmail.title",
      defaultMessage: "Connect your email",
    }),
    description: defineMessage({
      id: "userNotification.connectEmail.description",
      defaultMessage: "Save time with Nous AI.",
    }),
    iconName: "email_action_unread_regular",
  },
  ConnectWhatsAppUserNotification: {
    title: defineMessage({
      id: "userNotification.connectWhatsApp.title",
      defaultMessage: "Connect WhatsApp",
    }),
    description: defineMessage({
      id: "userNotification.connectWhatsApp.description",
      defaultMessage: "So we can contact you",
    }),
    iconName: "messaging_whatsapp",
  },
  RetoolDataCollectionUserNotification: {
    title: defineMessage({
      id: "userNotification.retoolDataCollection.title",
      defaultMessage: "We need some details from you",
    }),
    description: defineMessage({
      id: "userNotification.retoolDataCollection.description",
      defaultMessage: "Fill out this form so we can help.",
    }),
    iconName: "content_paper_edit_regular",
  },
}
