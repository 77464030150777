/**
 * @generated SignedSource<<2f9ec43430cfe283b1ed265a38fde262>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ShareInviteMutation$variables = Record<PropertyKey, never>;
export type ShareInviteMutation$data = {
  readonly retrieveOrCreateShareableInvite: {
    readonly invite: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type ShareInviteMutation = {
  response: ShareInviteMutation$data;
  variables: ShareInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RetrieveOrCreateShareableInviteResponse",
    "kind": "LinkedField",
    "name": "retrieveOrCreateShareableInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HouseholdMemberInvite",
        "kind": "LinkedField",
        "name": "invite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareInviteMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ShareInviteMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d7a047688f5400e1296e1d89c1112118",
    "id": null,
    "metadata": {},
    "name": "ShareInviteMutation",
    "operationKind": "mutation",
    "text": "mutation ShareInviteMutation {\n  retrieveOrCreateShareableInvite {\n    invite {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a31bbeff7ee4020abbb716743f99fc54";

export default node;
