import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"
import { z } from "zod"

import SettingsPageQuery, {
  SettingsPageQuery as SettingsPageQueryType,
} from "src/pages/SettingsPage/__generated__/SettingsPageQuery.graphql"
import { VIEWS } from "src/pages/SettingsPage/SettingsPage"
import { SettingsPageLayout } from "src/pages/SettingsPage/SettingsPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

const searchSchema = z.object({
  section: z.enum(VIEWS as [string]).optional(),
})

export const Route = createFileRoute("/settings")({
  validateSearch: searchSchema,
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  pendingComponent: SettingsPageLayout,
  loader: ({ context }) =>
    loadQuery<SettingsPageQueryType>(
      context.relayEnvironment(),
      SettingsPageQuery,
      {},
    ),

  component: lazyRouteComponent(
    () => import("src/pages/SettingsPage/SettingsPage"),
  ),
})
