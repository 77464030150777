import clsx from "clsx"

import { GlowFlexbox } from "src/glow"
import { GlowResponsiveSpacing, GlowSpacingTokens } from "src/glow/structure"

import OnboardingContentHeader, {
  Props as HeaderProps,
} from "./OnboardingContentHeader"

type Props = {
  children?: React.ReactNode
  topImage?: React.ReactNode
  header?: React.ReactNode
  cardMarginTop?: GlowSpacingTokens
  padding?: GlowResponsiveSpacing
  noHeader?: boolean
  className?: string
} & HeaderProps

export default function OnboardingContent({
  children,
  topImage,
  header,
  cardMarginTop: topMargin,
  padding,
  className,
  ...props
}: Props) {
  return (
    <GlowFlexbox
      margin={{
        sm: "4",
        md: {
          x: "4",
          top: "6",
          bottom: "4",
        },
      }}
      direction="column"
    >
      {topImage}
      <GlowFlexbox
        className={clsx("rounded-lg bg-white", className)}
        direction="column"
        alignItems="stretch"
        gap="6"
        margin={{ top: topMargin }}
        padding={
          padding ?? {
            bottom: "6",
            top: props.noHeader ? "6" : undefined,
          }
        }
      >
        {props.noHeader
          ? null
          : header || <OnboardingContentHeader {...props} />}
        {children && (
          <GlowFlexbox
            gap="6"
            direction="column"
            padding={{
              sm: {
                x: "4",
              },
              md: {
                x: "6",
              },
            }}
          >
            {children}
          </GlowFlexbox>
        )}
      </GlowFlexbox>
    </GlowFlexbox>
  )
}
