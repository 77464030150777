import React from "react"

import { getRouteApi } from "@tanstack/react-router"
import clsx from "clsx"
import { useIntl } from "react-intl"
import { graphql, usePreloadedQuery } from "react-relay"

import MetaTags from "src/components/MetaTags/MetaTags"
import { GlowFlexbox, GlowLayoutCentered } from "src/glow"
import usePageTrack from "src/tracking/usePageTrack"

import { SettingsPageQuery } from "./__generated__/SettingsPageQuery.graphql"
import { SettingsPageNavButton } from "./components/SettingsPageNavButton"
import { SettingsPageOverview } from "./components/SettingsPageOverview/SettingsPageOverview"
import { SettingsPageSavingsMode } from "./components/SettingsPageSavingsMode/SettingsPageSavingsMode"
import { SettingsPageLayout } from "./SettingsPageLayout"
import { SettingsPageSubPageBankAccounts } from "./subPages/SettingsPageSubPageBankAccounts"
import { SettingsPageSubPageInboxes } from "./subPages/SettingsPageSubPageInboxes"
import { SettingsPageSubPageMembers } from "./subPages/SettingsPageSubPageMembers"
import { SettingsPageSubPageNotifications } from "./subPages/SettingsPageSubPageNotifications"

const routeApi = getRouteApi("/settings")

const POSSIBLE_VIEWS = [
  "overview",
  "membership",
  "savings_mode",
  "account",
] as const
type POSSIBLE_VIEW = (typeof POSSIBLE_VIEWS)[number]

const POSSIBLE_SUB_VIEWS = [
  "members",
  "bank_accounts",
  "inboxes",
  "notifications",
] as const

export const VIEWS = [...POSSIBLE_VIEWS, ...POSSIBLE_SUB_VIEWS]
type VIEW = (typeof VIEWS)[number]

export const SettingsPage = () => {
  usePageTrack("Settings")

  const query = routeApi.useLoaderData()
  const params: { section?: VIEW } = routeApi.useSearch()
  const intl = useIntl()

  const data = usePreloadedQuery<SettingsPageQuery>(
    graphql`
      query SettingsPageQuery {
        household {
          ...SettingsPageOverview_household
          ...SettingsPageSavingsMode_household
          ...SettingsPageSubPageMembers_household
          ...SettingsPageSubPageBankAccounts_household
          ...SettingsPageSubPageInboxes_household
        }
      }
    `,
    query,
  )

  const [view, setView] = React.useState<VIEW>(params.section ?? "overview")

  return (
    <>
      <MetaTags
        title={intl.formatMessage({
          id: "page.settings.title",
          defaultMessage: "Settings",
        })}
        description={intl.formatMessage({
          id: "page.settings.description",
          defaultMessage: "Settings page",
        })}
      />

      {POSSIBLE_VIEWS.includes(view as POSSIBLE_VIEW) && (
        <SettingsPageLayout className="relative h-screen">
          <GlowFlexbox
            className="mx-auto w-full max-w-7xl px-4 md:mt-8"
            gap="2"
            alignItems="start"
            justifyContent="start"
          >
            <GlowFlexbox className="hidden md:flex">
              <GlowFlexbox direction="column" gap="4" alignItems="start">
                <SettingsPageNavButton
                  label={intl.formatMessage({
                    id: "page.settings.view.overview.title",
                    defaultMessage: "Overview",
                  })}
                  active={view === "overview"}
                  onClick={() => setView("overview")}
                />
                <SettingsPageNavButton
                  label={intl.formatMessage({
                    id: "page.settings.view.membership.title",
                    defaultMessage: "Membership",
                  })}
                  active={view === "membership"}
                  onClick={() => setView("membership")}
                />
                <SettingsPageNavButton
                  label={intl.formatMessage({
                    id: "page.settings.view.savings.title",
                    defaultMessage: "Savings mode",
                  })}
                  active={view === "savings_mode"}
                  onClick={() => setView("savings_mode")}
                />
                <SettingsPageNavButton
                  label={intl.formatMessage({
                    id: "page.settings.view.account.title",
                    defaultMessage: "Account",
                  })}
                  active={view === "account"}
                  onClick={() => setView("account")}
                />
              </GlowFlexbox>
            </GlowFlexbox>
            <GlowFlexbox className="mx-auto">
              <GlowLayoutCentered className={clsx("mx-4 mt-4 md:mx-0 md:mt-0")}>
                {view === "overview" && (
                  <SettingsPageOverview
                    household={data.household}
                    onGoToAccountView={() => setView("account")}
                    onGoToMembershipView={() => setView("membership")}
                    onGoToSavingsModeView={() => setView("savings_mode")}
                    onGoToMembersView={() => setView("members")}
                    onGoToBankAccountsView={() => setView("bank_accounts")}
                    onGoToInboxesView={() => setView("inboxes")}
                    onGoToNotificationsView={() => setView("notifications")}
                  />
                )}

                {view === "savings_mode" && (
                  <SettingsPageSavingsMode household={data.household} />
                )}
              </GlowLayoutCentered>
            </GlowFlexbox>
          </GlowFlexbox>
        </SettingsPageLayout>
      )}

      {view === "members" && (
        <SettingsPageSubPageMembers
          household={data.household}
          onBackToOverview={() => setView("overview")}
        />
      )}

      {view === "bank_accounts" && (
        <SettingsPageSubPageBankAccounts
          household={data.household}
          onBackToOverview={() => setView("overview")}
        />
      )}

      {view === "inboxes" && (
        <SettingsPageSubPageInboxes
          household={data.household}
          onBackToOverview={() => setView("overview")}
        />
      )}

      {view === "notifications" && (
        <SettingsPageSubPageNotifications
          onBackToOverview={() => setView("overview")}
        />
      )}
    </>
  )
}

export default SettingsPage
