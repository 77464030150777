/**
 * @generated SignedSource<<26af4915acbd2b7c8c25db39d98d2229>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageOverviewShareInvite_household$data = {
  readonly address: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateAccountPopup_address">;
  } | null | undefined;
  readonly currentMember: {
    readonly joinedHousehold: string | null | undefined;
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"ShareInvite_currentUser">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SettingsPageOverviewShareInvite_household";
};
export type SettingsPageOverviewShareInvite_household$key = {
  readonly " $data"?: SettingsPageOverviewShareInvite_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverviewShareInvite_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageOverviewShareInvite_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdMember",
      "kind": "LinkedField",
      "name": "currentMember",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "joinedHousehold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShareInvite_currentUser"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HouseholdAddress",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAccountPopup_address"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "50a7f4d320fae7aac5b6582923ed1ec7";

export default node;
