import React from "react"
import { useState } from "react"

import { useNavigate } from "@tanstack/react-router"
import { FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { useAuthActions, useUser } from "src/auth"
import {
  GlowButton,
  GlowFlexbox,
  GlowForm,
  GlowFormInput,
  GlowFormInputCheckbox,
  GlowIcon,
  GlowText,
} from "src/glow"
import { Viewport, useViewport } from "src/hooks/responsive"
import useIdentify from "src/hooks/useIdentify"
import { logger } from "src/logger"
import { useAppIntro } from "src/pages/DashboardPage/state"
import useTracking from "src/tracking/useTracking_LEGACY"
import { relayMutationToPromise } from "src/utils"

import { CreateAccountPopup_address$key } from "./__generated__/CreateAccountPopup_address.graphql"

import { Drawer } from "../Drawer/Drawer"
import { LoadingDots } from "../LoadingDots/LoadingDots"
import { Modal } from "../Modal/Modal"

const AddressFirstLine = ({
  address: addressKey,
}: {
  address: CreateAccountPopup_address$key | null
}) => {
  const intl = useIntl()
  const address = useFragment(
    graphql`
      fragment CreateAccountPopup_address on HouseholdAddress {
        firstLine
      }
    `,
    addressKey,
  )

  return (
    <GlowText
      isDisplay
      fontWeight="bold"
      className="max-w-[250px] truncate md:max-w-xs"
    >
      {address?.firstLine ??
        intl.formatMessage({
          id: "create_account_popup.address_fallback",
          defaultMessage: "Welcome",
        })}
    </GlowText>
  )
}

type CreateAccountFormData = {
  firstName: string
  lastName: string
  email: string
  password: string
  caseStudyConsent: boolean
}

type CreateAccountPopupContentProps = {
  onCreateAccount: (data: CreateAccountFormData) => void
  creatingAccount: boolean
  onContinueAsGuest: () => void
  address: CreateAccountPopup_address$key | null
}

const CreateAccountPopupContent = ({
  onCreateAccount,
  creatingAccount,
  onContinueAsGuest,
  address: addressKey,
}: CreateAccountPopupContentProps) => {
  const intl = useIntl()
  const track = useTracking()
  const [formState, setFormState] = useState<CreateAccountFormData>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    caseStudyConsent: false,
  })
  return (
    <GlowFlexbox direction="column" gap="4" className="w-full px-4 md:px-0">
      <GlowFlexbox
        direction="column"
        gap="3"
        className="w-[calc(100% + 3rem)] -mx-4 bg-gray-50 px-4 py-4 md:-mx-6 md:-mt-6 md:px-6"
      >
        <React.Suspense fallback={<LoadingDots />}>
          <AddressFirstLine address={addressKey} />
        </React.Suspense>
        <GlowFlexbox direction="column" gap="2">
          {[
            intl.formatMessage({
              id: "createAccountPopup.reason1",
              defaultMessage: "Keep track of providers all in one place",
            }),
            intl.formatMessage({
              id: "createAccountPopup.reason2",
              defaultMessage: "Access exclusive members-only savings",
            }),
            intl.formatMessage({
              id: "createAccountPopup.reason3",
              defaultMessage: "We'll handle all the hassle for you",
            }),
            intl.formatMessage({
              id: "createAccountPopup.reason4",
              defaultMessage: "Get cash rewards when you switch",
            }),
          ].map((reason, id) => (
            <GlowFlexbox gap="3" key={id}>
              <GlowIcon
                name="check_circle_1_bold"
                className="text-serene-blue-400 h-4 w-4"
              />
              <GlowText size="xs">{reason}</GlowText>
            </GlowFlexbox>
          ))}
        </GlowFlexbox>
      </GlowFlexbox>
      <GlowForm
        value={formState}
        onChange={setFormState}
        onSubmit={({ password, ...nonSensitiveData }) => {
          track("Submitted create account form", {
            method: "Create account popup",
            ...nonSensitiveData,
          })
          onCreateAccount({ password, ...nonSensitiveData })
        }}
      >
        <GlowFlexbox gap="4" direction="column">
          <GlowFlexbox gap="4">
            <GlowFormInput
              autoComplete="given-name"
              formKey="firstName"
              label={intl.formatMessage({
                id: "createAccountPopup.firstName",
                defaultMessage: "First name",
              })}
              placeholder={intl.formatMessage({
                id: "createAccountPopup.firstName.placeholder",
                defaultMessage: "Jane",
              })}
              getErrors={(value) => {
                return [
                  value.length < 1 &&
                    intl.formatMessage({
                      id: "createAccountPopup.firstName.requiredError",
                      defaultMessage: "First name is required",
                    }),
                ]
              }}
            />
            <GlowFormInput
              autoComplete="family-name"
              formKey="lastName"
              label={intl.formatMessage({
                id: "createAccountPopup.lastName",
                defaultMessage: "Last name",
              })}
              placeholder={intl.formatMessage({
                id: "createAccountPopup.lastName.placeholder",
                defaultMessage: "Doe",
              })}
              getErrors={(value) => {
                return [
                  value.length < 1 &&
                    intl.formatMessage({
                      id: "createAccountPopup.lastName.requiredError",
                      defaultMessage: "Last name is required",
                    }),
                ]
              }}
            />
          </GlowFlexbox>
          <GlowFormInput
            autoComplete="email"
            formKey="email"
            label={intl.formatMessage({
              id: "createAccountPopup.email",
              defaultMessage: "E-mail address",
            })}
            placeholder={intl.formatMessage({
              id: "createAccountPopup.email.placeholder",
              defaultMessage: "email@domain.com",
            })}
            getErrors={(value) => {
              if (value.length < 1) {
                return [
                  intl.formatMessage({
                    id: "createAccountPopup.email.requiredError",
                    defaultMessage: "Please enter your email address",
                  }),
                ]
              }
              if (!value.includes("@")) {
                return [
                  intl.formatMessage({
                    id: "createAccountPopup.email.invalidError",
                    defaultMessage:
                      "That email address doesn't look right - please try again",
                  }),
                ]
              }
              return []
            }}
          />
          <GlowFormInput
            autoComplete="new-password"
            type="password"
            formKey="password"
            label={intl.formatMessage({
              id: "createAccountPopup.password",
              defaultMessage: "Password",
            })}
            placeholder={
              intl.formatMessage({
                id: "createAccountPopup.password.placeholder",
                defaultMessage: "At least 10 characters",
              }) as string
            }
            getErrors={(value) => {
              if (!value) {
                return [
                  intl.formatMessage({
                    id: "createAccountPopup.password.requiredError",
                    defaultMessage: "Please enter a password",
                  }),
                ]
              }
              return [
                value.length < 10 &&
                  intl.formatMessage({
                    id: "createAccountPopup.password.invalidError",
                    defaultMessage:
                      "Your password must be at least 10 characters",
                  }),
              ]
            }}
          />
          <GlowFormInputCheckbox
            labelFontSize={"xs"}
            label={
              <FormattedMessage
                id="createAccountPopup.caseStudyConsent.label"
                defaultMessage="I'm open to sharing my 'cost of living story' to help others. <a>Learn more</a>"
                values={{
                  a: (chunks) => (
                    <a
                      href={intl.formatMessage({
                        id: "createAccountPopup.caseStudyConsent.link",
                        defaultMessage: "https://www.nous.co/case-study",
                      })}
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            }
            formKey="caseStudyConsent"
          />
          <GlowButton
            type="submit"
            className="w-full"
            label={intl.formatMessage({
              id: "createAccountPopup.createAccountButton",
              defaultMessage: "Create my account",
            })}
            isLoading={creatingAccount}
          />
        </GlowFlexbox>
      </GlowForm>

      <GlowButton
        variant="tertiary"
        className="w-full"
        onClick={() => {
          track("Clicked continue as guest")
          onContinueAsGuest()
        }}
        label={intl.formatMessage({
          id: "createAccountPopup.continueAsGuestButton",
          defaultMessage: "Continue as guest",
        })}
      />
      <GlowText size="xs" textAlign="center">
        <FormattedMessage
          id="createAccountPopup.termsOfService"
          defaultMessage="By signing up you agree with our <a>terms of service</a>."
          values={{
            a: (children: React.ReactNode) => (
              <a
                href={intl.formatMessage({
                  id: "createAccountPopup.termsOfService.link",
                  defaultMessage: "https://www.nous.co/terms-of-use",
                })}
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                {children}
              </a>
            ),
          }}
        />
      </GlowText>
    </GlowFlexbox>
  )
}

type CreateAccountPopupProps = {
  isOpen: boolean
  /**
   * This function is called when the popup is closed.
   * NOTE: There is already tracking.
   * @returns {void}
   */
  onClose: () => void
  /**
   * This function is called when an account is created successfully.
   * NOTE: There is already tracking.
   * NOTE: If this is not provided, the user will be redirected to the dashboard.
   * @returns {void}
   */
  onAccountCreated?: () => void

  address: CreateAccountPopup_address$key | null
}

const CreateAccountPopup = ({
  isOpen,
  onClose,
  onAccountCreated,
  address: addressKey,
}: CreateAccountPopupProps) => {
  const track = useTracking()
  const identify = useIdentify()
  const currentUser = useUser()
  const { authTransaction } = useAuthActions()
  const viewport = useViewport()
  const intl = useIntl()
  const [creatingAccount, setCreatingAccount] = React.useState(false)

  const [upgradeToFullAccount] = useMutation(graphql`
    mutation CreateAccountPopupUpgradeToFullAccountMutation(
      $input: UpgradeToFullAccountInput!
    ) {
      upgradeToFullAccount(input: $input) {
        id
      }
    }
  `)

  const [_, setAppIntro] = useAppIntro()
  const navigate = useNavigate()

  const createAccount = async (data: CreateAccountFormData) => {
    if (!currentUser) {
      return
    }
    setCreatingAccount(true)
    try {
      await authTransaction(async ({ linkAccountWithCredentials }) => {
        await linkAccountWithCredentials({
          email: data.email,
          password: data.password,
        })
        await relayMutationToPromise(upgradeToFullAccount, {
          variables: {
            input: {
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
            },
          },
        })
      })
      identify({
        traits: {
          caseStudyConsent: data.caseStudyConsent,
        },
      })
      track("Completed create account form", {
        method: "Create account popup",
      })
      track("Create account popup closed", {
        trigger: "Create account form submitted",
      })
      onClose()

      if (onAccountCreated) {
        onAccountCreated()
        return
      }

      navigate({ to: "/" })
      setAppIntro({ enabled: true })
    } catch (error) {
      logger.error("Error upgrading to full account", error as Error)
      track("Failed create account form", {
        method: "Create account popup",
      })
    } finally {
      setCreatingAccount(false)
    }
  }

  if (viewport < Viewport.MD) {
    return (
      <Drawer
        open={isOpen}
        onClose={() => {
          track("Create account popup closed", {
            trigger: "User clicked close button",
          })
          onClose()
        }}
        noPadding
      >
        <div className="relative overflow-hidden">
          <CreateAccountPopupContent
            address={addressKey}
            onCreateAccount={createAccount}
            creatingAccount={creatingAccount}
            onContinueAsGuest={() => {
              track("Create account popup closed", {
                trigger: "User clicked continue as guest button",
              })
              onClose()
            }}
          />
          <GlowIcon
            name="login_keys_bold"
            className="text-serene-blue-500 absolute -top-2 right-4 w-[64px]"
          />
        </div>
      </Drawer>
    )
  }

  return (
    <Modal
      header={intl.formatMessage({
        id: "createAccountPopup.header",
        defaultMessage: "Claim my household",
      })}
      isOpen={isOpen}
      onClose={() => {
        track("Create account popup closed", {
          trigger: "User clicked close button",
        })
        onClose()
      }}
    >
      <div className="relative">
        <CreateAccountPopupContent
          address={addressKey}
          onCreateAccount={createAccount}
          creatingAccount={creatingAccount}
          onContinueAsGuest={() => {
            track("Create account popup closed", {
              trigger: "User clicked continue as guest button",
            })
            onClose()
          }}
        />
        <GlowIcon
          name="login_keys_bold"
          className="text-serene-blue-500 absolute -top-10 right-0 w-[81px]"
        />
      </div>
    </Modal>
  )
}

export default CreateAccountPopup
