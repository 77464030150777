import { useCallback, useEffect, useState } from "react"

import {
  defineMessage,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

import {
  GlowButton,
  GlowFlexbox,
  GlowIcon,
  GlowIconName,
  GlowText,
} from "src/glow"
import { useViewport, Viewport } from "src/hooks/responsive"
import useShare from "src/hooks/useShare"
import useTracking from "src/tracking/useTracking"
import { relayMutationToPromise } from "src/utils"

import { ShareInvite_currentUser$key } from "./__generated__/ShareInvite_currentUser.graphql"
import { ShareInviteMutation } from "./__generated__/ShareInviteMutation.graphql"

type ShareInviteProps = {
  currentUser: ShareInvite_currentUser$key | null
  onShareViaEmail: () => void
}

const baseUrlRecord: Record<string, string> = {
  development: "http://localhost:3000/sign-up",
  staging: "https://app.staging.nous.co/sign-up",
  production: "https://app.nous.co/sign-up",
}

function buildButtonContent({
  shareMethod,
  shared,
}: {
  shareMethod: "share" | "clipboard"
  shared: boolean
}): { icon: GlowIconName; label: MessageDescriptor } {
  if (shareMethod === "share") {
    return shared
      ? {
          icon: "check_circle_1_bold",
          label: defineMessage({
            id: "component.shareInvite.button.shared",
            defaultMessage: "Shared",
          }),
        }
      : {
          icon: "send_email_regular",
          label: defineMessage({
            id: "component.shareInvite.button.share",
            defaultMessage: "Share",
          }),
        }
  }

  return shared
    ? {
        icon: "check_circle_1_bold",
        label: defineMessage({
          id: "component.shareInvite.button.copied",
          defaultMessage: "Link copied",
        }),
      }
    : {
        icon: "copy_1_regular",
        label: defineMessage({
          id: "component.shareInvite.button.copy",
          defaultMessage: "Copy Link",
        }),
      }
}

export default function ShareInvite(props: ShareInviteProps) {
  const intl = useIntl()
  const track = useTracking()
  const [inviteId, setInviteId] = useState("")
  const share = useShare()
  const [error, setError] = useState(false)

  const currentUser = useFragment(
    graphql`
      fragment ShareInvite_currentUser on User {
        firstName
      }
    `,
    props.currentUser,
  )

  const [retrieveOrCreateShareableInvite, isGeneratingInvite] =
    useMutation<ShareInviteMutation>(graphql`
      mutation ShareInviteMutation {
        retrieveOrCreateShareableInvite {
          invite {
            id
          }
        }
      }
    `)

  const generateInvite = useCallback(async () => {
    await relayMutationToPromise(retrieveOrCreateShareableInvite, {
      variables: {},
      onCompleted: (response) => {
        setInviteId(response.retrieveOrCreateShareableInvite?.invite?.id ?? "")
      },
      onError: () => {
        setInviteId("")
        setError(true)
      },
    })
  }, [retrieveOrCreateShareableInvite])

  useEffect(() => {
    generateInvite()
  }, [generateInvite])

  const shareableInviteUrl = `${baseUrlRecord[__NOUS_ENV__]}?invite=${inviteId}&utm_source=invite`

  const handleShareLink = async () => {
    await share.share({
      url: shareableInviteUrl,
      title: intl.formatMessage(
        {
          id: "component.shareInvite.shareMessage.title",
          defaultMessage: "Join {userName} household",
        },
        { userName: `${currentUser?.firstName}'s` ?? "your" },
      ),
      ...(share.method === "share"
        ? {
            text: intl.formatMessage({
              id: "component.shareInvite.shareMessage.text",
              defaultMessage:
                "Hi, I'm setting up Nous for our household. I've invited you to join as you pay some of the bills. Follow the link to accept the invitation and learn how Nous saves you money and time on household bills.",
            }),
          }
        : { text: "" }),
    })

    track(["Invite", "Shared", { inviteId }])

    await new Promise((resolve) => setTimeout(resolve, 3_000))

    share.setShareState((state) => ({
      ...state,
      isShared: false,
    }))
  }

  const viewport = useViewport()

  if (error) {
    return null
  }

  return (
    <GlowFlexbox direction="column" gap="4" className="w-full">
      <GlowText as="h3" fontWeight="bold">
        <FormattedMessage
          id="component.shareInvite.title"
          defaultMessage="Share invite link"
        />
      </GlowText>
      <GlowFlexbox direction="column" gap="3">
        <GlowFlexbox gap="2">
          <GlowFlexbox
            justifyContent="start"
            alignItems="center"
            className="w-[60%] overflow-y-scroll text-nowrap rounded-lg bg-gray-100 p-2 pl-3"
          >
            {isGeneratingInvite ? (
              <GlowFlexbox gap="1" justifyContent="center" alignItems="center">
                <GlowIcon
                  name="loading_regular"
                  className="text-off-black-64 h-4 w-4 animate-[spin_3s_linear_infinite]"
                />
                <GlowText as="p" size="sm" className="text-off-black-64">
                  <FormattedMessage
                    id="component.shareInvite.generating"
                    defaultMessage="Generating invite..."
                  />
                </GlowText>
              </GlowFlexbox>
            ) : (
              <GlowText as="p" size="sm" className="text-off-black-64">
                {shareableInviteUrl}
              </GlowText>
            )}
          </GlowFlexbox>

          <GlowButton
            variant="primary"
            iconName={
              buildButtonContent({
                shareMethod: share.method,
                shared: share.isShared,
              }).icon
            }
            iconClassName={
              share.isShared ? " text-virtuous-green-400" : undefined
            }
            isDisabled={isGeneratingInvite || share.isSharing}
            onClick={handleShareLink}
            label={intl.formatMessage(
              buildButtonContent({
                shareMethod: share.method,
                shared: share.isShared,
              }).label,
            )}
            className="w-[40%]"
          />
        </GlowFlexbox>

        <GlowText as="p" size="sm" className="text-off-black-64">
          <FormattedMessage
            id="component.shareInvite.disclaimer"
            defaultMessage="Anyone with access to this link can join your household."
          />
        </GlowText>
      </GlowFlexbox>

      <GlowButton
        className="w-full"
        variant="tertiary"
        onClick={props.onShareViaEmail}
        size={viewport < Viewport.MD ? "sm" : "base"}
        label={intl.formatMessage({
          id: "component.shareInvite.viaEmail",
          defaultMessage: "Send invite via email",
        })}
      />
    </GlowFlexbox>
  )
}
