import React from "react"

import clsx from "clsx"

import { GlowIcon, GlowIconName } from "./GlowIcon"
import { GlowText } from "./GlowText"
import { GlowResponsiveSpacing, glowSpacingToClassNames } from "./structure"

type GlowMembershipBadgeSize = "base" | "sm"

export type GlowBadgeProps = {
  label?: string
  size?: GlowMembershipBadgeSize
  className?: string | null
  labelClassName?: string | null
  margin?: GlowResponsiveSpacing
} & (
  | {
      variant?: "member"
      icon?: never
      iconName?: never
      iconClassName?: never
    }
  | {
      variant?: "default" | "bold" | "success" | "lightBlue" | "white"
      icon?: React.ReactElement
      iconName?: GlowIconName
      iconClassName?: string
    }
)

const backgroundColours = {
  default: "bg-gray-50",
  bold: "bg-gray-50",
  success: "bg-virtuous-green-500",
  member: "bg-gradient-to-br from-nous-glow-200 to-nous-glow-300",
  lightBlue: "bg-serene-blue-100",
  white: "bg-white",
}

const fontsColours = {
  default: "text-gray-500",
  bold: "text-gray-500",
  success: "text-white",
  member: "text-nous-glow-700",
  lightBlue: "text-serene-blue-700",
  white: "text-gray-500",
}

export function GlowBadge({
  label,
  icon,
  iconName,
  iconClassName,
  className,
  labelClassName,
  size = "base",
  variant = "default",
  margin,
}: GlowBadgeProps) {
  const cloneIcon = (icon?: React.ReactElement) => {
    if (!icon) {
      return null
    }

    const { className, ...props } = icon.props

    return React.cloneElement(icon, {
      className: clsx(size === "base" ? "w-5 h-5" : "w-4 h-4", className),
      ...(variant === "success" && { fill: "#fff" }),
      ...props,
    })
  }

  return (
    <div
      className={clsx(
        "flex flex-row items-center rounded-full",
        size === "base" ? "gap-2.5 p-2" : "gap-1.5 px-2 py-1.5",
        backgroundColours[variant],
        className,
        ...glowSpacingToClassNames(margin ?? {}, "m"),
      )}
    >
      {variant !== "member" &&
        (iconName ? (
          <GlowIcon
            name={iconName}
            className={clsx(
              size === "base" ? "h-5 w-5" : "h-4 w-4",
              variant === "success" ? "text-white" : "text-gray-500",
              iconClassName,
            )}
          />
        ) : (
          cloneIcon(icon)
        ))}

      {variant === "member" && (
        <div className={clsx("bg-nous-glow-700 h-3 w-3 rounded-t-full")} />
      )}

      {label && (
        <GlowText
          size={size === "base" ? "sm" : "xs"}
          fontWeight={variant === "bold" ? "bold" : "medium"}
          className={clsx(fontsColours[variant], labelClassName)}
        >
          {label}
        </GlowText>
      )}
    </div>
  )
}
