import { FormattedMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowButton, GlowCard, GlowFlexbox, GlowText } from "src/glow"

import { SettingsPageOverviewMembership_household$key } from "./__generated__/SettingsPageOverviewMembership_household.graphql"

export const SettingsPageOverviewMembership = (props: {
  household: SettingsPageOverviewMembership_household$key
  onGoToMembershipView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewMembership_household on Household {
        currentMember {
          joinedHousehold
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  return (
    <GlowCard>
      <GlowFlexbox direction="column" gap="6" alignItems="start">
        <GlowText fontWeight="bold">
          <FormattedMessage
            id="page.settings.view.overview.membershipDetailsTitle"
            defaultMessage="Membership details"
          />
        </GlowText>
        <GlowFlexbox direction="column" gap="2" alignItems="start">
          <GlowText size="xl" isDisplay>
            <FormattedMessage
              id="page.settings.view.overview.planType.free"
              defaultMessage="Free Plan"
            />
          </GlowText>
          {household.currentMember?.joinedHousehold && (
            <GlowText>
              <FormattedMessage
                id="page.settings.view.overview.memberJoinedHouseholdDate"
                defaultMessage="Member since {joinedHousehold}"
                values={{
                  joinedHousehold: intl.formatDate(
                    household.currentMember.joinedHousehold,
                    {
                      month: "long",
                      year: "numeric",
                    },
                  ),
                }}
              />
            </GlowText>
          )}
        </GlowFlexbox>
        <GlowButton
          variant="tertiary"
          label={intl.formatMessage({
            id: "page.settings.view.overview.goToMembershipView",
            defaultMessage: "Manage membership",
          })}
          className="w-full"
          onClick={props.onGoToMembershipView}
        />
      </GlowFlexbox>
    </GlowCard>
  )
}
