import { useIntl } from "react-intl"

import { SettingsPageSubPageLayout } from "./SettingsPageSubPageLayout"

export const SettingsPageSubPageNotifications = (props: {
  children?: React.ReactNode
  onBackToOverview: () => void
}) => {
  const intl = useIntl()

  return (
    <SettingsPageSubPageLayout
      title={intl.formatMessage({
        id: "page.settings.view.notifications.title",
        defaultMessage: "Notification preferences",
      })}
      onBackToOverview={props.onBackToOverview}
      className="relative h-screen"
    >
      {props.children}
    </SettingsPageSubPageLayout>
  )
}
