/**
 * @generated SignedSource<<ab6c3afc5e54bb06437dd7f1eb2ae203>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestPageQuery$variables = Record<PropertyKey, never>;
export type QuestPageQuery$data = {
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"QuestPageContent_household" | "useRedirectToWaitlist_household">;
  };
};
export type QuestPageQuery = {
  response: QuestPageQuery$data;
  variables: QuestPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "completed",
    "value": false
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dealCardExpiresAfterDays",
    "storageKey": null
  }
],
v4 = [
  (v2/*: any*/),
  {
    "kind": "TypeDiscriminator",
    "abstractKey": "__isIUserTask"
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "priority",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "completed",
    "storageKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v3/*: any*/),
    "type": "ReviewEnergyRecommendationUserTask",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v3/*: any*/),
    "type": "ReviewMobileRecommendationUserTask",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v3/*: any*/),
    "type": "ReviewBroadbandRecommendationUserTask",
    "abstractKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "quest",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": "incompleteTasks",
  "args": (v0/*: any*/),
  "concreteType": null,
  "kind": "LinkedField",
  "name": "userTasks",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": "userTasks(completed:false)"
},
v6 = {
  "alias": "completeTasks",
  "args": [
    {
      "kind": "Literal",
      "name": "completed",
      "value": true
    }
  ],
  "concreteType": null,
  "kind": "LinkedField",
  "name": "userTasks",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": "userTasks(completed:true)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuestPageContent_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useRedirectToWaitlist_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QuestPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "quests",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inFocus",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "ISavingsQuest",
                "abstractKey": "__isISavingsQuest"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "ISetupQuest",
                "abstractKey": "__isISetupQuest"
              }
            ],
            "storageKey": "quests(completed:false)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inWaitlist",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDelegated",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4216a5af746e76491c73553f59c0c48",
    "id": null,
    "metadata": {},
    "name": "QuestPageQuery",
    "operationKind": "query",
    "text": "query QuestPageQuery {\n  household {\n    ...QuestPageContent_household\n    ...useRedirectToWaitlist_household\n    id\n  }\n}\n\nfragment QuestPageContent_household on Household {\n  quests(completed: false) {\n    id\n    __typename\n    ...QuestPageSavingsQuest_quest\n    ...QuestPageSetupQuest_quest\n  }\n}\n\nfragment QuestPageQuestCompleteTasks_task on IUserTask {\n  __isIUserTask: __typename\n  id\n  ...UserTask_task\n}\n\nfragment QuestPageQuestIncompleteTasks_task on IUserTask {\n  __isIUserTask: __typename\n  id\n  __typename\n  ...UserTask_task\n}\n\nfragment QuestPageSavingsQuest_quest on ISavingsQuest {\n  __isISavingsQuest: __typename\n  ...SavingsQuestBadge_quest\n  incompleteTasks: userTasks(completed: false) {\n    __typename\n    ...QuestPageQuestIncompleteTasks_task\n    id\n  }\n  completeTasks: userTasks(completed: true) {\n    __typename\n    ...QuestPageQuestCompleteTasks_task\n    id\n  }\n}\n\nfragment QuestPageSetupQuest_quest on ISetupQuest {\n  __isISetupQuest: __typename\n  incompleteTasks: userTasks(completed: false) {\n    __typename\n    ...QuestPageQuestIncompleteTasks_task\n    id\n  }\n  completeTasks: userTasks(completed: true) {\n    __typename\n    ...QuestPageQuestCompleteTasks_task\n    id\n  }\n}\n\nfragment SavingsQuestBadge_quest on ISavingsQuest {\n  __isISavingsQuest: __typename\n  __typename\n  inFocus\n}\n\nfragment UserTask_task on IUserTask {\n  __isIUserTask: __typename\n  id\n  priority\n  completed\n  __typename\n  ... on ReviewEnergyRecommendationUserTask {\n    dealCardExpiresAfterDays\n  }\n  ... on ReviewMobileRecommendationUserTask {\n    dealCardExpiresAfterDays\n  }\n  ... on ReviewBroadbandRecommendationUserTask {\n    dealCardExpiresAfterDays\n  }\n  quest {\n    __typename\n    id\n  }\n}\n\nfragment useRedirectToWaitlist_household on Household {\n  inWaitlist\n  serviceLevel\n  isDelegated\n}\n"
  }
};
})();

(node as any).hash = "3138bf48593a380a8b714ba3f49936f4";

export default node;
