import { useContext } from "react"
import React from "react"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import emily from "src/assets/emilyColour.png"

import { GlowFlexbox } from "./GlowFlexbox"
import { GlowText } from "./GlowText"
import { GlowResponsiveSpacing, glowSpacingToClassNames } from "./structure"

type GlowCardProps = {
  children: React.ReactNode
  noPadding?: boolean
  noBorder?: boolean
  colour?:
    | "white"
    | "yellow"
    | "light-yellow"
    | "gray"
    | "transparent"
    | "gradient"
    | "serene-blue"
  variant?: "default" | "asymmetrical" | "foldedCorner"
  margin?: GlowResponsiveSpacing
} & React.ComponentProps<"div">

const GlowCardContext = React.createContext(0)

const cornerFoldClasses =
  "relative overflow-hidden before:content-[''] before:absolute before:top-0 before:right-0 before:radius-lg before:bg-gray-50 before:block before:border-[16px] before:w-0 before:border-t-gray-100 before:border-r-gray-100 before:border-b-transparent before:border-l-transparent before:rounded-bl-lg"

const mapColorToBubble = {
  white: "text-white",
  yellow: "text-nous-glow-400",
  "light-yellow": "textztous-glow-200",
  gray: "text-gray-50",
  transparent: "text-white",
  gradient: "text-[#FDC66D]",
  "serene-blue": "text-serene-blue-100",
}

export const GlowCard = React.forwardRef<HTMLDivElement, GlowCardProps>(
  (props, ref) => {
    const {
      noPadding,
      noBorder = false,
      className,
      colour = "white",
      variant = "default",
      margin,
      children,
      ...otherProps
    } = props
    const level = useContext(GlowCardContext)

    return (
      <div
        className={clsx(
          "rounded-lg",
          colour === "white" && "bg-white",
          colour === "yellow" && "bg-nous-glow-400",
          colour === "light-yellow" && "bg-nous-glow-200",
          colour === "gray" && "bg-gray-50",
          colour === "transparent" && "border-black/12 border bg-transparent",
          colour === "gradient" && "radial-gradient",
          colour === "serene-blue" && "bg-serene-blue-100",
          noPadding
            ? ""
            : level === 0
              ? `px-${GLOW_CARD_PADDING_X_SM} py-${GLOW_CARD_PADDING_Y} md:px-${GLOW_CARD_PADDING_X_MD}`
              : `p-${GLOW_CARD_PADDING_X}`,
          noBorder && "border-0",
          level > 0 && "border border-gray-500/10",
          variant === "asymmetrical" && "rounded-br-4xl",
          variant === "foldedCorner" && cornerFoldClasses,
          ...glowSpacingToClassNames(margin ?? {}, "m"),
          className,
        )}
        {...otherProps}
        ref={ref}
      >
        <GlowCardContext.Provider value={level + 1}>
          {children}
        </GlowCardContext.Provider>
      </div>
    )
  },
)

const GLOW_CARD_PADDING_X: GlowResponsiveSpacing = "4"
const GLOW_CARD_PADDING_X_SM: GlowResponsiveSpacing = "4"
const GLOW_CARD_PADDING_X_MD: GlowResponsiveSpacing = "6"

export const GLOW_CARD_PADDING_Y: GlowResponsiveSpacing = "6"

export const GlowCardWithEmily = React.forwardRef<
  HTMLDivElement,
  GlowCardProps
>((props, ref) => {
  return (
    <GlowFlexbox
      direction="column"
      gap="2"
      alignItems="center"
      justifyContent="center"
      className="w-full"
    >
      <GlowFlexbox
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="w-full"
      >
        <GlowCard {...props} ref={ref}>
          {props.children}
        </GlowCard>
        <SpeachBubble
          className={clsx(
            mapColorToBubble[props.colour ?? "white"],
            "mt-[-1px]",
          )}
        />
      </GlowFlexbox>
      <div
        className="h-14 w-14 rounded-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${emily})`,
        }}
      ></div>
    </GlowFlexbox>
  )
})

export const GlowCardUpNext = React.forwardRef<HTMLDivElement, GlowCardProps>(
  (props, ref) => {
    return (
      <GlowFlexbox
        direction="column"
        gap="2"
        alignItems="center"
        justifyContent="center"
        className="w-full"
      >
        <GlowText size="sm" className="bg-nous-glow-200 rounded-full px-6 py-2">
          <FormattedMessage id="upNextCard.label" defaultMessage="Up next:" />
        </GlowText>
        <GlowFlexbox
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="w-full"
        >
          <SpeachBubble
            className={clsx(
              mapColorToBubble[props.colour ?? "white"],
              "mb-[-1px] rotate-180",
            )}
          />
          <GlowCard {...props} ref={ref}>
            {props.children}
          </GlowCard>
        </GlowFlexbox>
      </GlowFlexbox>
    )
  },
)

const SpeachBubble = (props: { className?: string }) => {
  return (
    <svg
      width="25"
      height="13"
      viewBox="0 0 25 13"
      fill="none"
      className={props.className}
    >
      <path
        d="M24.5 0.0732422H0.5C0.5 0.0732422 6.11091 2.2232 8.5 4.73991C10.8891 7.25662 12.7353 12.0732 12.7353 12.0732C12.7353 12.0732 14.4381 7.25662 16.7353 4.73991C19.0325 2.2232 24.5 0.0732422 24.5 0.0732422Z"
        fill="currentColor"
      />
    </svg>
  )
}
