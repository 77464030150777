import { graphql, useFragment } from "react-relay"

import { GlowFlexbox } from "src/glow"

import { SettingsPageOverview_household$key } from "./__generated__/SettingsPageOverview_household.graphql"
import { SettingsPageOverviewBankAccounts } from "./components/SettingsPageOverviewBankAccounts"
import { SettingsPageOverviewHouse } from "./components/SettingsPageOverviewHouse"
import { SettingsPageOverviewInboxes } from "./components/SettingsPageOverviewInboxes"
import { SettingsPageOverviewMembers } from "./components/SettingsPageOverviewMembers"
import { SettingsPageOverviewMembership } from "./components/SettingsPageOverviewMembership"
import { SettingsPageOverviewNotifications } from "./components/SettingsPageOverviewNotifications"
import { SettingsPageOverviewSavingsMode } from "./components/SettingsPageOverviewSavingsMode"
import { SettingsPageOverviewShareInvite } from "./components/SettingsPageOverviewShareInvite"

export const SettingsPageOverview = (props: {
  household: SettingsPageOverview_household$key
  onGoToAccountView: () => void
  onGoToMembershipView: () => void
  onGoToSavingsModeView: () => void
  onGoToMembersView: () => void
  onGoToBankAccountsView: () => void
  onGoToInboxesView: () => void
  onGoToNotificationsView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverview_household on Household {
        ...SettingsPageOverviewHouse_household
        ...SettingsPageOverviewShareInvite_household
        ...SettingsPageOverviewMembership_household
        ...SettingsPageOverviewSavingsMode_household
        ...SettingsPageOverviewMembers_household
        ...SettingsPageOverviewBankAccounts_household
        ...SettingsPageOverviewInboxes_household
      }
    `,
    props.household,
  )

  return (
    <GlowFlexbox direction="column" gap="4">
      <SettingsPageOverviewHouse
        household={household}
        onGoToAccountView={props.onGoToAccountView}
      />
      <SettingsPageOverviewShareInvite household={household} />
      <SettingsPageOverviewMembership
        household={household}
        onGoToMembershipView={props.onGoToMembershipView}
      />
      <SettingsPageOverviewSavingsMode
        household={household}
        onGoToSavingsModeView={props.onGoToSavingsModeView}
      />
      <SettingsPageOverviewMembers
        household={household}
        onGoToMembersView={props.onGoToMembersView}
      />
      <SettingsPageOverviewBankAccounts
        household={household}
        onGoToBankAccountsView={props.onGoToBankAccountsView}
      />
      <SettingsPageOverviewInboxes
        household={household}
        onGoToInboxesView={props.onGoToInboxesView}
      />
      <SettingsPageOverviewNotifications
        onGoToNotificationsView={props.onGoToNotificationsView}
      />
    </GlowFlexbox>
  )
}
