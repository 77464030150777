import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import {
  GlowBadge,
  GlowButton,
  GlowCard,
  GlowFlexbox,
  GlowHorizontalDivider,
  GlowIcon,
  GlowText,
} from "src/glow"
import {
  SupportedManagement,
  categoryManagementIconMap,
  categoryManagementNameMap,
  handleFutureValueOnRelayEnumOrNull,
  serviceLevelDescriptionMap,
  serviceLevelImageMap,
  serviceLevelNameMap,
} from "src/utils"

import { SettingsPageOverviewSavingsMode_household$key } from "./__generated__/SettingsPageOverviewSavingsMode_household.graphql"

export const SettingsPageOverviewSavingsMode = (props: {
  household: SettingsPageOverviewSavingsMode_household$key
  onGoToSavingsModeView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewSavingsMode_household on Household {
        currentMember {
          joinedHousehold
        }
        serviceLevel
        serviceManagements {
          __typename
          enabled
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  const serviceLevel = handleFutureValueOnRelayEnumOrNull(
    household.serviceLevel,
    null,
  )

  if (!serviceLevel) {
    return null
  }

  const numberOfServices = household.serviceManagements?.length ?? 0

  const numberOfManagedServices =
    household.serviceManagements?.filter(
      (serviceManagement) => serviceManagement.enabled,
    ).length ?? 0

  return (
    <GlowCard>
      <GlowFlexbox direction="column" gap="6">
        <GlowFlexbox direction="column" gap="4" alignItems="center">
          <GlowFlexbox
            justifyContent="space-between"
            alignItems="center"
            className="w-full"
          >
            <GlowText fontWeight="bold">
              <FormattedMessage
                id="page.settings.view.overview.savingsModeTitle"
                defaultMessage="Savings mode"
              />
            </GlowText>
            <GlowBadge
              label={intl.formatMessage(serviceLevelNameMap[serviceLevel])}
              size="sm"
              variant="bold"
            />
          </GlowFlexbox>

          <img
            src={serviceLevelImageMap[serviceLevel]}
            alt={intl.formatMessage(serviceLevelNameMap[serviceLevel])}
            className="h-14 w-auto"
          />

          <GlowText size="lg" isDisplay textAlign="center">
            <FormattedMessage
              {...serviceLevelDescriptionMap[serviceLevel].header}
            />
          </GlowText>

          <GlowHorizontalDivider />

          {serviceLevel === "DO_EVERYTHING_MYSELF" ? (
            <GlowFlexbox alignItems="center" gap="2">
              <GlowIcon
                name="alert_circle_bold"
                className="h-4 w-auto text-gray-200"
              />

              <GlowText size="sm">
                <FormattedMessage
                  id="page.settings.view.overview.savingsModeManualAlert"
                  description="The alert shown above the activated service icons in manual mode informing the user everything is off in manual mode"
                  defaultMessage="We won't secure savings for you in Manual mode"
                />
              </GlowText>
            </GlowFlexbox>
          ) : (
            <GlowText size="sm">
              {intl.formatMessage<React.ReactNode>(
                {
                  id: "page.settings.view.overview.numberOfManagedServices",
                  description:
                    "The message shown above the activated service icons informing the how many services are being managed",
                  defaultMessage:
                    "We're securing savings for <bold>{numberOfManagedServices}</bold> services:",
                },
                {
                  numberOfManagedServices,
                  bold: (chunks) => (
                    <GlowText fontWeight="bold">{chunks}</GlowText>
                  ),
                },
              )}
            </GlowText>
          )}

          <GlowFlexbox
            gap="4"
            className={clsx("grid", `grid-cols-${numberOfServices}`)}
          >
            {household.serviceManagements?.map((serviceManagement) => (
              <GlowFlexbox
                gap="1"
                direction="column"
                alignItems="center"
                key={serviceManagement.__typename}
                className="col-span-1"
              >
                <GlowFlexbox
                  as="span"
                  className="relative grid h-10 w-10 place-items-center rounded-full bg-gray-50"
                >
                  <GlowIcon
                    name={
                      categoryManagementIconMap[
                        serviceManagement.__typename as SupportedManagement
                      ]
                    }
                    className="h-5 w-5 text-gray-500"
                  />

                  <GlowIcon
                    name="check_circle_1_bold"
                    className={clsx(
                      "absolute right-0 top-0 h-3 w-3",
                      serviceManagement.enabled
                        ? "text-virtuous-green-400"
                        : "text-gray-200",
                    )}
                  />
                </GlowFlexbox>

                <GlowText size="xs" className="text-center">
                  {
                    categoryManagementNameMap[
                      serviceManagement.__typename as SupportedManagement
                    ]
                  }
                </GlowText>
              </GlowFlexbox>
            ))}
          </GlowFlexbox>
        </GlowFlexbox>

        <GlowButton
          variant="tertiary"
          label={intl.formatMessage({
            id: "page.settings.view.overview.goToSavingsModeView",
            defaultMessage: "Manage savings mode",
          })}
          className="w-full"
          onClick={props.onGoToSavingsModeView}
        />
      </GlowFlexbox>
    </GlowCard>
  )
}
