import { FileRoutesByPath, Link } from "@tanstack/react-router"
import { useIntl } from "react-intl"

import { useCheckGate } from "src/Gate"
import { GlowIcon, GlowIconName } from "src/glow"
import useTracking from "src/tracking/useTracking"
import { filterNullsAndFalse } from "src/utils"

import MobileNavLinkTasksIcon from "./MobileNavLinkTasksIcon"

function MobileNavLink(props: {
  iconName?: { inactive: GlowIconName; active: GlowIconName }
  icon?: (opts: { active: boolean }) => React.ReactNode
  label: string
  to: keyof FileRoutesByPath
}) {
  const track = useTracking()

  return (
    <Link
      key="home"
      to={props.to}
      onClick={() => {
        track(["Navigation", "Clicked", { to: props.to }])
      }}
      className="opacity-64 flex w-full flex-col items-center py-2 text-gray-400"
      activeProps={{
        className: "!text-serene-blue-600 font-bold !opacity-100",
      }}
    >
      {({ isActive }) => (
        <>
          {props.iconName ? (
            <GlowIcon
              name={isActive ? props.iconName.active : props.iconName.inactive}
              className={isActive ? "text-serene-blue-600" : "text-black"}
            />
          ) : (
            props.icon?.({ active: isActive })
          )}
          <span className="mt-1 text-xs">{props.label}</span>
        </>
      )}
    </Link>
  )
}

export const MobileBottomNav = () => {
  const intl = useIntl()
  const checkGate = useCheckGate()

  const links = filterNullsAndFalse([
    <MobileNavLink
      key="home"
      to={"/"}
      iconName={{
        active: "house_entrance_bold",
        inactive: "house_entrance_regular",
      }}
      label={intl.formatMessage({
        id: "mobileNav.home",
        defaultMessage: "Home",
      })}
    />,
    <MobileNavLink
      key="savings"
      to={"/savings"}
      label={intl.formatMessage({
        id: "mobileNav.savings",
        defaultMessage: "Savings",
      })}
      iconName={{
        active: "currency_pound_bubble_bold",
        inactive: "currency_pound_bubble_regular",
      }}
    />,

    <MobileNavLink
      key="tasks"
      to={"/quests/"}
      label={intl.formatMessage({
        id: "mobileNav.tasks",
        defaultMessage: "To-do",
      })}
      icon={(opts) => <MobileNavLinkTasksIcon isActive={opts.active} />}
    />,
    <MobileNavLink
      key="documents"
      to={"/documents/"}
      label={intl.formatMessage({
        id: "mobileNav.docs",
        defaultMessage: "Docs",
      })}
      iconName={{
        active: "common_file_double_3_bold",
        inactive: "common_file_double_3_regular",
      }}
    />,
    checkGate("settings_page") && (
      <MobileNavLink
        key="settings"
        to={"/settings"}
        label={intl.formatMessage({
          id: "mobileNav.settings",
          defaultMessage: "Settings",
        })}
        iconName={{
          active: "cog_bold",
          inactive: "cog_regular",
        }}
      />
    ),
  ])
  return (
    <div className="z-mobile-bottom-nav sticky bottom-0 border-t bg-white">
      <div className="z-mobile-bottom-nav flex">{links}</div>
    </div>
  )
}
