import { MessageDescriptor, useIntl } from "react-intl"

import { Logo } from "src/components/Logo/Logo"
import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowPageLayout } from "src/glow/GlowPageLayout"

export const ServiceDetailsLoading = (props: { title: MessageDescriptor }) => {
  const intl = useIntl()

  return (
    <>
      <MetaTags
        title={intl.formatMessage(props.title)}
        description={intl.formatMessage({
          id: "serviceDetails.meta.description.loading",
          defaultMessage:
            "Please bare with us while we load the information for your service.",
        })}
      />
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              backgroundColour="nous-glow"
              topComponent={<Logo className="h-5" />}
              showAvatar
              showNotificationsButton
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      />
    </>
  )
}
