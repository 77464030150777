import React from "react"

import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { useUser } from "src/auth"
import Avatar from "src/components/Avatar/Avatar"
import {
  InviteModal,
  useInviteModal,
} from "src/components/InviteModal/InviteModal"
import { GlowButton, GlowCard, GlowFlexbox, GlowLink, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { usePopup } from "src/hooks/modal"

import { SettingsPageSubPageMembers_household$key } from "./__generated__/SettingsPageSubPageMembers_household.graphql"
import { SettingsPageSubPageMembersCancelInviteMutation } from "./__generated__/SettingsPageSubPageMembersCancelInviteMutation.graphql"
import { SettingsPageSubPageLayout } from "./SettingsPageSubPageLayout"

const memberOrInviteName = defineMessage({
  id: "page.settings.view.members.memberOrInviteName",
  defaultMessage: "{firstName} {lastName}",
})

export const SettingsPageSubPageMembers = (props: {
  household: SettingsPageSubPageMembers_household$key
  onBackToOverview: () => void
}) => {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment SettingsPageSubPageMembers_household on Household {
        members {
          id
          user {
            ...Avatar_user
            firstName
            lastName
            id
            email
          }
        }
        pendingInvites {
          id
          ...Avatar_invite
          firstName
          lastName
          email
          ...InviteModalPendingInvite_invite
        }
      }
    `,
    props.household,
  )

  const inviteModal = useInviteModal()
  const user = useUser()

  const [selectedInvite, setSelectedInvite] = React.useState<
    (typeof household.pendingInvites)[number] | null
  >(null)

  const [cancelInvite, cancellingInvite] =
    useMutation<SettingsPageSubPageMembersCancelInviteMutation>(graphql`
      mutation SettingsPageSubPageMembersCancelInviteMutation($id: ID!) {
        cancelHouseholdInviteV2(id: $id) {
          household {
            ...SettingsPageSubPageMembers_household
          }
        }
      }
    `)
  const cancelnvitePopup = usePopup()

  return (
    <SettingsPageSubPageLayout
      title={intl.formatMessage({
        id: "page.settings.view.members.title",
        defaultMessage: "Household members",
      })}
      onBackToOverview={props.onBackToOverview}
      className="relative h-screen"
    >
      <GlowCard>
        <GlowFlexbox direction="column" gap="6" alignItems="center">
          <GlowText textAlign="center" fontWeight="medium">
            <FormattedMessage
              id="page.settings.view.members.header"
              defaultMessage="Add or manage other bill payers in your household"
            />
          </GlowText>

          {household.members.map((member) => {
            if (!member.user) {
              return null
            }

            return (
              <GlowCard colour="transparent" key={member.id} className="w-full">
                <GlowFlexbox gap="6" alignItems="center">
                  <Avatar user={member.user} size="base" />

                  <GlowFlexbox direction="column" gap="1" alignItems="start">
                    <GlowFlexbox gap="1">
                      <GlowText>
                        <FormattedMessage
                          {...memberOrInviteName}
                          values={{
                            firstName: member.user.firstName,
                            lastName: member.user.lastName,
                          }}
                        />
                      </GlowText>

                      {member.user.id === user?.id && (
                        <GlowText fontWeight="bold">
                          <FormattedMessage
                            id="page.settings.view.members.currentUser"
                            defaultMessage="(You)"
                          />
                        </GlowText>
                      )}
                    </GlowFlexbox>

                    <GlowText size="sm" className="text-gray-500/64">
                      {member.user.email}
                    </GlowText>
                  </GlowFlexbox>
                </GlowFlexbox>
              </GlowCard>
            )
          })}

          {household.pendingInvites.map((invite) => (
            <GlowCard colour="transparent" key={invite.id} className="w-full">
              <GlowFlexbox alignItems="center" justifyContent="space-between">
                <GlowFlexbox gap="6" alignItems="center" justifyContent="start">
                  <Avatar invite={invite} size="base" />

                  <GlowFlexbox direction="column" gap="1" alignItems="start">
                    <GlowText>
                      <FormattedMessage
                        {...memberOrInviteName}
                        values={{
                          firstName: invite.firstName,
                          lastName: invite.lastName,
                        }}
                      />
                    </GlowText>

                    <GlowText size="sm" className="text-gray-500/64">
                      {invite.email}
                    </GlowText>
                  </GlowFlexbox>
                </GlowFlexbox>

                <GlowLink
                  label={intl.formatMessage({
                    id: "page.settings.view.members.invite.cancelButton",
                    defaultMessage: "Cancel",
                  })}
                  onClick={() => {
                    setSelectedInvite(invite)
                    cancelnvitePopup.open()
                  }}
                />
              </GlowFlexbox>
            </GlowCard>
          ))}

          <GlowButton
            variant={household.members.length ? "secondary" : "primary"}
            label={intl.formatMessage({
              id: "page.settings.view.members.add",
              defaultMessage: "Add another household member",
            })}
            iconName="add_regular"
            size="sm"
            className="w-full"
            onClick={inviteModal.onOpen}
          />
        </GlowFlexbox>
      </GlowCard>

      <InviteModal query={inviteModal.query} onClose={inviteModal.onClose} />

      {selectedInvite && (
        <GlowModal
          isOpen={cancelnvitePopup.isOpen}
          onClose={cancelnvitePopup.close}
          disableManualClose={cancellingInvite}
          title={intl.formatMessage(
            {
              id: "page.settings.view.members.invite.cancel.confirmation.title",
              defaultMessage: "Are you sure?",
            },
            {
              firstName: selectedInvite.firstName,
              lastName: selectedInvite.lastName,
              email: selectedInvite.email,
            },
          )}
        >
          <GlowFlexbox direction="column" gap="4" alignItems="center">
            <GlowText textAlign="center">
              <FormattedMessage
                id="page.settings.view.members.invite.cancel.confirmation.text"
                defaultMessage="Are you sure you want to cancel the invite for {firstName} {lastName} ({email})?"
                values={{
                  firstName: selectedInvite.firstName,
                  lastName: selectedInvite.lastName,
                  email: selectedInvite.email,
                }}
              />
            </GlowText>

            <GlowButton
              variant="primary"
              label={intl.formatMessage({
                id: "page.settings.view.members.invite.cancel.confirmation.no",
                defaultMessage: "No, go back",
              })}
              onClick={cancelnvitePopup.close}
              className="w-full"
            />

            <GlowButton
              variant="secondary"
              label={intl.formatMessage({
                id: "page.settings.view.members.invite.cancel.confirmation.yes",
                defaultMessage: "Yes, cancel invite",
              })}
              onClick={() =>
                cancelInvite({
                  variables: { id: selectedInvite.id },
                  onCompleted: cancelnvitePopup.close,
                })
              }
              className="w-full"
            />
          </GlowFlexbox>
        </GlowModal>
      )}
    </SettingsPageSubPageLayout>
  )
}
