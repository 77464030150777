import React from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import { BankAccount } from "src/components/BankAccount"
import { BankAccountAddOrEditPopup } from "src/components/BankAccountAddOrEditPopup"
import {
  GlowButton,
  GlowCard,
  GlowFlexbox,
  GlowHorizontalDivider,
  GlowIcon,
  GlowLink,
  GlowText,
} from "src/glow"
import { usePopup } from "src/hooks/modal"
import useTracking from "src/tracking/useTracking"

import { SettingsPageSubPageBankAccounts_household$key } from "./__generated__/SettingsPageSubPageBankAccounts_household.graphql"
import { SettingsPageSubPageLayout } from "./SettingsPageSubPageLayout"

export const SettingsPageSubPageBankAccounts = (props: {
  household: SettingsPageSubPageBankAccounts_household$key
  onBackToOverview: () => void
}) => {
  const intl = useIntl()
  const track = useTracking()

  const household = useFragment(
    graphql`
      fragment SettingsPageSubPageBankAccounts_household on Household {
        bankAccounts {
          id
          ...BankAccount
          ...BankAccountAddOrEditPopup_bankAccount
        }
      }
    `,
    props.household,
  )

  const [selectedBankAccount, setSelectedBankAccount] = React.useState<
    (typeof household.bankAccounts)[number] | null
  >(null)

  const addOrEditBankAccountPopup = usePopup()

  return (
    <SettingsPageSubPageLayout
      title={intl.formatMessage({
        id: "page.settings.view.bankAccounts.title",
        defaultMessage: "Bank accounts",
      })}
      onBackToOverview={props.onBackToOverview}
      className="relative h-screen"
    >
      <GlowCard>
        <GlowFlexbox direction="column" gap="6" alignItems="center">
          <GlowText textAlign="center" fontWeight="medium">
            <FormattedMessage
              id="page.settings.view.bankAccounts.header"
              defaultMessage="Add details of a bank account that's registered with your home address so we can set up new providers for you."
            />
          </GlowText>

          {household.bankAccounts.map((bankAccount) => (
            <GlowCard
              colour="transparent"
              key={bankAccount.id}
              className="w-full"
            >
              <BankAccount
                bankAccount={bankAccount}
                onEdit={() => {
                  setSelectedBankAccount(bankAccount)
                  addOrEditBankAccountPopup.open()
                }}
              />
            </GlowCard>
          ))}

          <GlowButton
            variant={household.bankAccounts.length ? "secondary" : "primary"}
            label={
              household.bankAccounts.length
                ? intl.formatMessage({
                    id: "page.settings.view.bankAccounts.add",
                    defaultMessage: "Add another bank account",
                  })
                : intl.formatMessage({
                    id: "page.settings.view.bankAccounts.addAnother",
                    defaultMessage: "Add a bank account",
                  })
            }
            iconName="add_regular"
            size="sm"
            className="w-full"
            onClick={addOrEditBankAccountPopup.open}
          />

          <GlowHorizontalDivider />

          <GlowCard colour="gray">
            <GlowFlexbox gap="3" alignItems="center">
              <GlowIcon
                name="alert_circle_regular"
                className="h-5 w-auto shrink-0"
              />

              <GlowText size="xs">
                {intl.formatMessage<React.ReactNode>(
                  {
                    id: "page.settings.view.bankAccounts.alert",
                    defaultMessage:
                      "<bold>Please note:</bold> You're on a free plan with Nous and we'll never charge you without your explicit approval. We'll only use these details when we need to set up new providers or send you cash rewards. <a>Learn more</a>",
                  },
                  {
                    bold: (chunk) => <span className="font-bold">{chunk}</span>,
                    a: (chunk) => (
                      <GlowLink
                        size="xs"
                        target="_blank"
                        href={intl.formatMessage({
                          id: "page.settings.view.bankAccounts.alert.link",
                          defaultMessage: "https://nous.app/privacy",
                        })}
                        label={chunk}
                        className="inline-flex"
                      />
                    ),
                  },
                )}
              </GlowText>
            </GlowFlexbox>
          </GlowCard>
        </GlowFlexbox>
      </GlowCard>

      <BankAccountAddOrEditPopup
        bankAccount={selectedBankAccount}
        isOpen={addOrEditBankAccountPopup.isOpen}
        onClose={() => {
          addOrEditBankAccountPopup.close()
          setSelectedBankAccount(null)
        }}
        onAdded={() => {
          track(["BankAccount", "DetailsEntrySaved", { mode: "Add" }])
          addOrEditBankAccountPopup.close()
          setSelectedBankAccount(null)
        }}
        onEdited={() => {
          track(["BankAccount", "DetailsEntrySaved", { mode: "Edit" }])
          addOrEditBankAccountPopup.close()
          setSelectedBankAccount(null)
        }}
      />
    </SettingsPageSubPageLayout>
  )
}
