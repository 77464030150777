import clsx from "clsx"
import { useIntl } from "react-intl"

import { GlowFlexbox, GlowIcon, GlowText } from "src/glow"
import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum, serviceLevelBenefitsMap } from "src/utils"

export const ServiceLevelSelectBenefits = (props: {
  selectedServiceLevel: CheckedRelayEnum<NousServiceLevel>
}) => {
  const intl = useIntl()

  return (
    <GlowFlexbox direction="row" gap="6" alignItems="start">
      {serviceLevelBenefitsMap[props.selectedServiceLevel].map(
        (benefit, index) => (
          <GlowFlexbox
            direction="column"
            alignItems="center"
            gap="3"
            key={index}
            className="w-1/3"
          >
            <GlowIcon
              name={benefit.icon}
              className={clsx(
                "h-6 w-6",
                benefit.missed && "text-angsty-red-400",
              )}
            />
            <GlowText size="sm" textAlign="center">
              {intl.formatMessage(benefit.text)}
            </GlowText>
          </GlowFlexbox>
        ),
      )}
    </GlowFlexbox>
  )
}
