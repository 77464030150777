import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowLayoutCentered } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function YourHouseholdPageLayout(props: {
  children?: React.ReactNode
}) {
  return (
    <>
      <MetaTags title="Profile" />
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              title="Household settings"
              showBack
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        <GlowLayoutCentered className="!h-auto pb-8 pt-6">
          {props.children ?? <LoadingPagePlaceholder />}
        </GlowLayoutCentered>
      </GlowPageLayout>
    </>
  )
}
