/**
 * @generated SignedSource<<6c94e59ae4732332b5ab631a36cff1db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceManagementEnabledContext = "ADMIN_OPERATION" | "DELEGATION_PROMPT_EMAIL" | "DELEGATION_TASK" | "INDUCTION_DELEGATION" | "SETTINGS_PAGE" | "%future added value";
export type CreateServiceTypeManagementsInput = {
  broadband: boolean;
  context?: ServiceManagementEnabledContext | null | undefined;
  energy: boolean;
  mobile: boolean;
  mortgage: boolean;
};
export type SettingsPageSavingsModeCreateServiceTypeManagementsMutation$variables = {
  input: CreateServiceTypeManagementsInput;
};
export type SettingsPageSavingsModeCreateServiceTypeManagementsMutation$data = {
  readonly createServiceTypeManagements: {
    readonly household: {
      readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSavingsMode_household">;
    } | null | undefined;
  } | null | undefined;
};
export type SettingsPageSavingsModeCreateServiceTypeManagementsMutation = {
  response: SettingsPageSavingsModeCreateServiceTypeManagementsMutation$data;
  variables: SettingsPageSavingsModeCreateServiceTypeManagementsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsPageSavingsModeCreateServiceTypeManagementsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateServiceTypeManagementsResponse",
        "kind": "LinkedField",
        "name": "createServiceTypeManagements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SettingsPageSavingsMode_household"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsPageSavingsModeCreateServiceTypeManagementsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateServiceTypeManagementsResponse",
        "kind": "LinkedField",
        "name": "createServiceTypeManagements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Household",
            "kind": "LinkedField",
            "name": "household",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "serviceManagements",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bcb05b71febba8ee87187f8f072757cb",
    "id": null,
    "metadata": {},
    "name": "SettingsPageSavingsModeCreateServiceTypeManagementsMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsPageSavingsModeCreateServiceTypeManagementsMutation(\n  $input: CreateServiceTypeManagementsInput!\n) {\n  createServiceTypeManagements(input: $input) {\n    household {\n      ...SettingsPageSavingsMode_household\n      id\n    }\n  }\n}\n\nfragment SettingsPageSavingsMode_household on Household {\n  id\n  serviceLevel\n  serviceManagements {\n    __typename\n    enabled\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "05feac91b8814ea152fa514bb698bc9f";

export default node;
