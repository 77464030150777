import { useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowModal } from "src/glow/GlowModal"

import { BankAccountAddOrEditPopup_bankAccount$key } from "./__generated__/BankAccountAddOrEditPopup_bankAccount.graphql"
import BankAccountDetailsGlowForm from "./BankDetailsForm/BankAccountDetailsGlowForm"

export const BankAccountAddOrEditPopup = (props: {
  bankAccount: BankAccountAddOrEditPopup_bankAccount$key | null
  isOpen: boolean
  onClose: () => void
  onAdded: () => void
  onEdited: () => void
}) => {
  const intl = useIntl()

  const bankAccount = useFragment(
    graphql`
      fragment BankAccountAddOrEditPopup_bankAccount on BankAccount {
        ...BankAccountDetailsGlowForm_bankAccount
      }
    `,
    props.bankAccount,
  )

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={
        props.bankAccount
          ? intl.formatMessage({
              id: "component.BankAccountAddOrEditPopup.title.edit",
              defaultMessage: "Edit account details",
            })
          : intl.formatMessage({
              id: "component.BankAccountAddOrEditPopup.title.add",
              defaultMessage: "Add account details",
            })
      }
    >
      <BankAccountDetailsGlowForm
        bankAccount={bankAccount}
        onCompleted={() => {
          if (props.bankAccount) {
            props.onEdited()
          } else {
            props.onAdded()
          }
        }}
      />
    </GlowModal>
  )
}
