/**
 * @generated SignedSource<<8c448ed75f94643186b6409c3bb891bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NousServiceLevel = "DO_ALL_OF_IT_FOR_ME" | "DO_EVERYTHING_MYSELF" | "DO_MOST_OF_IT_FOR_ME" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSavingsMode_household$data = {
  readonly id: string;
  readonly serviceLevel: NousServiceLevel | null | undefined;
  readonly serviceManagements: ReadonlyArray<{
    readonly __typename: string;
    readonly enabled: boolean | null | undefined;
  }>;
  readonly " $fragmentType": "SettingsPageSavingsMode_household";
};
export type SettingsPageSavingsMode_household$key = {
  readonly " $data"?: SettingsPageSavingsMode_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSavingsMode_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageSavingsMode_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "serviceManagements",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "3c72819653ea41a9b6cddd2288ece767";

export default node;
