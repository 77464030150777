/**
 * @generated SignedSource<<4b9f89514e152236cdccac3bad2680c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingBankDetailsPageQuery$variables = Record<PropertyKey, never>;
export type OnboardingBankDetailsPageQuery$data = {
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"OnboardingBankDetailsPageAccountDetailsAdditionStep_household">;
  };
};
export type OnboardingBankDetailsPageQuery = {
  response: OnboardingBankDetailsPageQuery$data;
  variables: OnboardingBankDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingBankDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OnboardingBankDetailsPageAccountDetailsAdditionStep_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnboardingBankDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "rewardsBankAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "644778b5d0a9561140b18bf49bef08ab",
    "id": null,
    "metadata": {},
    "name": "OnboardingBankDetailsPageQuery",
    "operationKind": "query",
    "text": "query OnboardingBankDetailsPageQuery {\n  household {\n    ...OnboardingBankDetailsPageAccountDetailsAdditionStep_household\n    id\n  }\n}\n\nfragment BankAccount on BankAccount {\n  title\n  firstName\n  lastName\n  accountNumber\n  sortCode\n}\n\nfragment BankAccountAddOrEditPopup_bankAccount on BankAccount {\n  ...BankAccountDetailsGlowForm_bankAccount\n}\n\nfragment BankAccountDetailsGlowForm_bankAccount on BankAccount {\n  title\n  firstName\n  lastName\n  sortCode\n  accountNumber\n}\n\nfragment OnboardingBankDetailsPageAccountDetailsAdditionStep_bankAccount on BankAccount {\n  ...BankAccountAddOrEditPopup_bankAccount\n  ...BankAccount\n}\n\nfragment OnboardingBankDetailsPageAccountDetailsAdditionStep_household on Household {\n  rewardsBankAccount {\n    id\n    ...OnboardingBankDetailsPageAccountDetailsAdditionStep_bankAccount\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ada2d4f9e824af3f6f398871357257d";

export default node;
