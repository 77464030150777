import { Breadcrumbs } from "src/components/Breadcrumbs/Breadcrumbs"
import { GlowFlexbox, GlowLayoutCentered } from "src/glow"

import { SettingsPageLayout } from "../SettingsPageLayout"

export const SettingsPageSubPageLayout = (props: {
  title: string
  children?: React.ReactNode
  className?: string
  onBackToOverview: () => void
}) => {
  return (
    <SettingsPageLayout className={props.className}>
      <GlowFlexbox
        className="mx-auto hidden w-full max-w-7xl md:block"
        padding={{ x: "4" }}
        margin={{
          md: {
            top: "8",
          },
        }}
      >
        <Breadcrumbs
          crumbs={[
            { text: "Settings", onClick: props.onBackToOverview },
            { text: props.title },
          ]}
        />
      </GlowFlexbox>

      <GlowLayoutCentered className="md:mt-8">
        {props.children}
      </GlowLayoutCentered>
    </SettingsPageLayout>
  )
}
