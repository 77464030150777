import { useIntl } from "react-intl"

import { GlowText } from "src/glow"
import { NousServiceLevel } from "src/hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { CheckedRelayEnum, serviceLevelDescriptionMap } from "src/utils"

export const ServiceLevelSelectDescriptionHeader = (props: {
  selectedServiceLevel: CheckedRelayEnum<NousServiceLevel>
}) => {
  const intl = useIntl()

  return (
    <GlowText
      size={{ sm: "base", md: "lg" }}
      fontWeight="medium"
      isDisplay
      textAlign="center"
    >
      {intl.formatMessage(
        serviceLevelDescriptionMap[props.selectedServiceLevel].header,
      )}
    </GlowText>
  )
}
