import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"

import { Header } from "src/components/Header/Header"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowFlexbox, GlowIcon, GlowLink, GlowText } from "src/glow"
import {
  GlowLayoutCentered,
  GlowLayoutCenteredVariant,
} from "src/glow/GlowLayout"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import { Viewport, useViewport } from "src/hooks/responsive"

type OnboardingLayoutProps = {
  children: React.ReactNode
  onBack?: () => void
  secureForm?: boolean
  variant?: "default" | "gray"
  width?: GlowLayoutCenteredVariant
  backgroundColourOverride?: `bg-${string}`
  noLayout?: boolean
} & (
  | { title: React.ReactNode; logo?: never }
  | { title?: never; logo: React.ReactNode }
)

export default function OnboardingLayout({
  children,
  title,
  logo,
  onBack,
  secureForm,
  variant = "default",
  width = "narrow",
  backgroundColourOverride,
  noLayout,
}: OnboardingLayoutProps) {
  const viewport = useViewport()
  const intl = useIntl()

  return (
    <div
      className={clsx(
        "h-full",
        !backgroundColourOverride &&
          (variant === "default" ? "radial-gradient" : "bg-gray-100"),
        backgroundColourOverride,
      )}
    >
      <GlowPageLayout
        mobileNav={{
          top: (
            <>
              {title ? (
                typeof title === "string" ? (
                  <MobileTopNav
                    variant="secondary"
                    onBack={onBack}
                    title={title}
                    showBack={onBack != null}
                    customMenu={
                      secureForm ? (
                        <GlowIcon
                          name="lock_2_regular"
                          className="text-gray-500"
                        />
                      ) : undefined
                    }
                  />
                ) : (
                  <MobileTopNav
                    variant="secondary"
                    topComponent={title}
                    onBack={onBack}
                    showBack={onBack != null}
                    customMenu={
                      secureForm ? (
                        <GlowIcon
                          name="lock_2_regular"
                          className="text-gray-500"
                        />
                      ) : undefined
                    }
                  />
                )
              ) : (
                <MobileTopNav
                  variant="secondary"
                  onBack={onBack}
                  showBack={onBack != null}
                  topComponent={logo}
                  customMenu={
                    secureForm ? (
                      <GlowIcon
                        name="lock_2_regular"
                        className="text-gray-500"
                      />
                    ) : undefined
                  }
                />
              )}
            </>
          ),
          bottom: null,
        }}
        desktopNav={
          <Header>
            <Header.Logo />
            {secureForm && (
              <GlowFlexbox gap="2">
                <GlowIcon name="lock_shield_bold" className="text-gray-500" />
                <FormattedMessage
                  id="induction.secureForm"
                  defaultMessage="Secure form"
                />
              </GlowFlexbox>
            )}
          </Header>
        }
      >
        {noLayout ? (
          <>{children}</>
        ) : (
          <GlowLayoutCentered variant={width}>
            {viewport > Viewport.SM &&
              (typeof title === "string" ? (
                <GlowText
                  size="2xl"
                  fontWeight="medium"
                  isDisplay
                  textAlign="center"
                  margin={{ top: "12" }}
                  className="block w-full"
                >
                  {title}
                </GlowText>
              ) : (
                <div className="mt-12">{title}</div>
              ))}
            {children}
            {onBack && viewport > Viewport.SM && (
              <GlowFlexbox justifyContent="center">
                <GlowLink
                  onClick={onBack}
                  label={intl.formatMessage({
                    id: "induction.back",
                    defaultMessage: "<- Back",
                  })}
                />
              </GlowFlexbox>
            )}
          </GlowLayoutCentered>
        )}
      </GlowPageLayout>
    </div>
  )
}
