import clsx from "clsx"
import { useIntl } from "react-intl"

import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowPageLayout } from "src/glow/GlowPageLayout"

export const SettingsPageLayout = (props: {
  children?: React.ReactNode
  className?: string
}) => {
  const intl = useIntl()
  return (
    <div className={clsx("min-h-full bg-gray-100", props.className)}>
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              backgroundColour="white"
              title={intl.formatMessage({
                id: "page.settings.mobileNav.title",
                defaultMessage: "Settings",
              })}
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        {props.children}
      </GlowPageLayout>
    </div>
  )
}
