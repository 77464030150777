import { useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { SettingsPageOverviewInboxes_household$key } from "./__generated__/SettingsPageOverviewInboxes_household.graphql"
import { SettingsPageOverviewGenericSection } from "./SettingsPageOverviewGenericSection"

export const SettingsPageOverviewInboxes = (props: {
  household: SettingsPageOverviewInboxes_household$key
  onGoToInboxesView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverviewInboxes_household on Household {
        emailConnections {
          id
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  return (
    <SettingsPageOverviewGenericSection
      icon={"email_action_unread_regular"}
      title={intl.formatMessage({
        id: "page.settings.view.overview.inboxesTitle",
        defaultMessage: "Connected inboxes",
      })}
      value={household.emailConnections.length}
      subtitle={intl.formatMessage({
        id: "page.settings.view.overview.inboxesActionDescription",
        defaultMessage: "Manage connected email inboxes",
      })}
      onClick={props.onGoToInboxesView}
    />
  )
}
