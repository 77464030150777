import React, { useCallback } from "react"

import { useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { notify } from "src/components/ToastNotification/ToastNotification"
import { GlowFlexbox, GlowLink } from "src/glow"
import { CreateEmailConnectionInput } from "src/pages/ConectEmailPage/__generated__/ConnectEmailPageMutation.graphql"
import { OnboardingConnectEmailBenefitsStepContent } from "src/pages/Onboarding/steps/ai/OnboardingConnectEmailBenefitsStep/components/OnboardingConnectEmailBenefitsStepContent"
import OnboardingConnectEmailStepInitialContent from "src/pages/Onboarding/steps/ai/OnboardingConnectEmailStep/components/OnboardingConnectEmailStepInitialContent"
import useTracking from "src/tracking/useTracking"
import { handleFutureValueOnRelayEnum } from "src/utils"

import { SettingsPageSubPageInboxes_household$key } from "./__generated__/SettingsPageSubPageInboxes_household.graphql"
import { SettingsPageSubPageInboxesCreateEmailConnectionMutation } from "./__generated__/SettingsPageSubPageInboxesCreateEmailConnectionMutation.graphql"
import { SettingsPageSubPageLayout } from "./SettingsPageSubPageLayout"

export const SettingsPageSubPageInboxes = (props: {
  household: SettingsPageSubPageInboxes_household$key
  onBackToOverview: () => void
}) => {
  const intl = useIntl()
  const track = useTracking()

  const household = useFragment(
    graphql`
      fragment SettingsPageSubPageInboxes_household on Household {
        ...OnboardingConnectEmailBenefitsStepContent_household
      }
    `,
    props.household,
  )

  const [showConnectEmailStep, setShowConnectEmailStep] = React.useState(false)

  const [createEmailConnection] =
    useMutation<SettingsPageSubPageInboxesCreateEmailConnectionMutation>(
      graphql`
        mutation SettingsPageSubPageInboxesCreateEmailConnectionMutation(
          $input: CreateEmailConnectionInput!
        ) {
          createEmailConnection(input: $input) {
            household {
              ...OnboardingConnectEmailBenefitsStepContent_household
              ...DashboardPageQuests_household
            }
          }
        }
      `,
    )

  const onConnectEmail = useCallback(
    (input: CreateEmailConnectionInput) => {
      const source =
        input?.source == null
          ? "GOOGLE"
          : handleFutureValueOnRelayEnum(input?.source, "GOOGLE")
      const emailProvider = source === "GOOGLE" ? "Google" : "Microsoft"

      createEmailConnection({
        variables: { input },
        onCompleted: (_data, errors) => {
          if (errors && errors.length > 0) {
            notify.error("Failed to connect email, please try again.")
            setShowConnectEmailStep(false)
            return
          }
          setShowConnectEmailStep(false)

          track(["EmailConnection", "Succeeded", { emailProvider }])
        },
        onError: () => {
          notify.error("Failed to connect email, please try again.")
          setShowConnectEmailStep(false)
        },
      })
    },
    [createEmailConnection, track],
  )

  return (
    <SettingsPageSubPageLayout
      title={intl.formatMessage({
        id: "page.settings.view.inboxes.title",
        defaultMessage: "Household inboxes",
      })}
      onBackToOverview={props.onBackToOverview}
      className="relative h-screen"
    >
      {showConnectEmailStep ? (
        <>
          <OnboardingConnectEmailStepInitialContent
            onOutlookConnection={async ({ code, redirectUri }) => {
              onConnectEmail({
                code,
                redirectUri,
                source: "MICROSOFT",
                connectedThrough: "SETTINGS_PAGE",
              })
            }}
            onGmailConnection={(code) => {
              onConnectEmail({
                code,
                source: "GOOGLE",
                connectedThrough: "SETTINGS_PAGE",
              })
            }}
            skipReasonFormAnswer={null}
            hideSkipButton
          />

          <GlowFlexbox justifyContent="center">
            <GlowLink
              onClick={() => setShowConnectEmailStep(false)}
              label={intl.formatMessage({
                id: "connectEmailPage.back",
                defaultMessage: "<- Back",
              })}
            />
          </GlowFlexbox>
        </>
      ) : (
        <OnboardingConnectEmailBenefitsStepContent
          household={household}
          onConnectEmail={() => setShowConnectEmailStep(true)}
          hideBackButton
          hideSkipButton
        />
      )}
    </SettingsPageSubPageLayout>
  )
}
