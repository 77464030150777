import React from "react"

import clsx from "clsx"
import {
  defineMessage,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { ServiceLevelSelectBenefits } from "src/components/ServiceLevel/ServiceLevelSelectBenefits"
import { ServiceLevelSelectButtons } from "src/components/ServiceLevel/ServiceLevelSelectButtons"
import { ServiceLevelSelectDescriptionHeader } from "src/components/ServiceLevel/ServiceLevelSelectDescriptionHeader"
import { ServiceLevelSelectDescriptionParagraph } from "src/components/ServiceLevel/ServiceLevelSelectDescriptionParagraph"
import {
  GlowBadge,
  GlowButton,
  GlowCard,
  GlowFlexbox,
  GlowHorizontalDivider,
  GlowIcon,
  GlowText,
  GlowToggle,
} from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { usePopup } from "src/hooks/modal"
import useToggle from "src/hooks/useToggle"
import { CreateServiceTypeManagementsInput } from "src/pages/Onboarding/OnboardingServiceLevelPage/hooks/__generated__/useCreateServiceTypeManagements_createServiceTypeManagementsMutation.graphql"
import useTracking from "src/tracking/useTracking"
import {
  capitalize,
  categoryManagementIconMap,
  categoryManagementNameMap,
  CheckedRelayEnum,
  handleFutureValueOnRelayEnumOrNull,
  serviceLevelNameMap,
  SupportedManagement,
} from "src/utils"

import {
  NousServiceLevel,
  SettingsPageSavingsMode_household$key,
} from "./__generated__/SettingsPageSavingsMode_household.graphql"
import { SettingsPageSavingsModeCreateServiceTypeManagementsMutation } from "./__generated__/SettingsPageSavingsModeCreateServiceTypeManagementsMutation.graphql"
import { SettingsPageSavingsModeUpdateHouseholdMutation } from "./__generated__/SettingsPageSavingsModeUpdateHouseholdMutation.graphql"

const DEFAULT_SERVICE_LEVEL: NousServiceLevel = "DO_MOST_OF_IT_FOR_ME"

export const SettingsPageSavingsMode = (props: {
  household: SettingsPageSavingsMode_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageSavingsMode_household on Household {
        id
        serviceLevel
        serviceManagements {
          __typename
          enabled
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  const serviceLevel = handleFutureValueOnRelayEnumOrNull(
    household.serviceLevel,
    null,
  )

  const [selectedServiceLevel, setSelectedServiceLevel] = React.useState<
    CheckedRelayEnum<NousServiceLevel>
  >(serviceLevel ?? DEFAULT_SERVICE_LEVEL)

  const [updateHousehold, updatingHousehold] =
    useMutation<SettingsPageSavingsModeUpdateHouseholdMutation>(graphql`
      mutation SettingsPageSavingsModeUpdateHouseholdMutation(
        $id: ID!
        $serviceLeveL: NousServiceLevel!
      ) {
        updateHousehold(id: $id, input: { serviceLevel: $serviceLeveL }) {
          household {
            serviceLevel
            ...OnboardingServiceLevelPageChoiceConfirmationStep_household
            ...DashboardPageQuests_household
            ...QuestsPageContent_household
            ...QuestPageContent_household
            ...SettingsPageOverview_household
            ...SettingsPageSavingsMode_household
          }
        }
      }
    `)

  const [createServiceTypeManagements, creatingServiceTypeManagements] =
    useMutation<SettingsPageSavingsModeCreateServiceTypeManagementsMutation>(
      graphql`
        mutation SettingsPageSavingsModeCreateServiceTypeManagementsMutation(
          $input: CreateServiceTypeManagementsInput!
        ) {
          createServiceTypeManagements(input: $input) {
            household {
              ...SettingsPageSavingsMode_household
            }
          }
        }
      `,
    )

  const changingServiceLevel =
    updatingHousehold || creatingServiceTypeManagements

  const [serviceTypeManagementEnablement, setServiceTypeManagementEnablement] =
    React.useState<Omit<CreateServiceTypeManagementsInput, "context">>({
      broadband: household.serviceManagements.some(
        (management) =>
          management.__typename === "BroadbandManagement" && management.enabled,
      ),
      energy: household.serviceManagements.some(
        (management) =>
          management.__typename === "EnergyManagement" && management.enabled,
      ),
      mobile: household.serviceManagements.some(
        (management) =>
          management.__typename === "MobileManagement" && management.enabled,
      ),

      mortgage: household.serviceManagements.some(
        (management) =>
          management.__typename === "MortgageManagement" && management.enabled,
      ),
    })

  const numberOfManagedServices = household.serviceManagements.filter(
    (management) => management.enabled,
  ).length

  const handleManualConfirmed = () => {
    setServiceTypeManagementEnablement({
      broadband: false,
      energy: false,
      mobile: false,
      mortgage: false,
    })

    createServiceTypeManagements({
      variables: {
        input: {
          broadband: false,
          energy: false,
          mobile: false,
          mortgage: false,
          context: "SETTINGS_PAGE",
        },
      },
      onCompleted: changeServiceLevelPopup.close,
    })
  }

  const information = useToggle(false)

  const changeServiceLevelPopup = usePopup()

  const track = useTracking()

  return (
    <>
      <GlowFlexbox direction="column" gap="4">
        <GlowCard>
          {serviceLevel && (
            <GlowFlexbox justifyContent="space-between" alignItems="center">
              <GlowText fontWeight="bold">
                <FormattedMessage
                  id="page.settings.view.savingsMode.currentServiceLevel"
                  defaultMessage="Current savings mode"
                />
              </GlowText>

              <GlowBadge
                label={intl.formatMessage(serviceLevelNameMap[serviceLevel])}
                size="sm"
                variant="bold"
              />
            </GlowFlexbox>
          )}
        </GlowCard>

        <GlowCard>
          <GlowFlexbox direction="column">
            <div className="mb-6">
              <ServiceLevelSelectButtons
                selectedServiceLevel={selectedServiceLevel}
                onSelect={(serviceLevel) => {
                  setSelectedServiceLevel(serviceLevel)
                  information.setTrue()
                }}
                hideOnboardingLayout
              />
            </div>

            <ServiceLevelSelectDescriptionHeader
              selectedServiceLevel={selectedServiceLevel}
            />

            {information.value && (
              <GlowFlexbox
                direction="column"
                justifyContent="center"
                gap="6"
                margin={{
                  top: "4",
                }}
              >
                <ServiceLevelSelectDescriptionParagraph
                  selectedServiceLevel={selectedServiceLevel}
                />

                <ServiceLevelSelectBenefits
                  selectedServiceLevel={selectedServiceLevel}
                />

                {selectedServiceLevel === serviceLevel && (
                  <GlowText
                    fontStyle="italic"
                    fontWeight="bold"
                    size="sm"
                    textAlign="center"
                  >
                    <FormattedMessage
                      id="page.settings.view.savingsMode.information.currentMode"
                      defaultMessage="You're currently on this mode."
                    />
                  </GlowText>
                )}

                {selectedServiceLevel !== serviceLevel && (
                  <GlowButton
                    variant="primary"
                    size="sm"
                    className="w-full"
                    label={intl.formatMessage(
                      {
                        id: "page.settings.view.savingsMode.information.action",
                        defaultMessage: "-> Switch to {serviceLevel} mode",
                      },
                      {
                        serviceLevel: capitalize(
                          intl.formatMessage(
                            serviceLevelNameMap[selectedServiceLevel],
                          ),
                        ),
                      },
                    )}
                    onClick={changeServiceLevelPopup.open}
                  />
                )}
              </GlowFlexbox>
            )}

            <button className="group" onClick={information.toggle}>
              <GlowFlexbox
                direction="column"
                justifyContent="center"
                gap="4"
                margin={{
                  top: information.value ? "4" : "6",
                }}
              >
                <GlowHorizontalDivider />

                <GlowFlexbox justifyContent="center" gap="3">
                  <GlowIcon
                    name="arrow_down_1_regular"
                    className={clsx(
                      "text-gray-500/64 h-5 w-5 transform transition-transform duration-200 group-hover:text-gray-500",
                      information.value && "rotate-180",
                    )}
                  />

                  <GlowText className="text-gray-500/64 group-hover:text-gray-500 group-hover:underline">
                    {information.value ? (
                      <FormattedMessage
                        id="page.settings.view.savingsMode.information.less"
                        defaultMessage="Less about {serviceLevel} mode"
                        values={{
                          serviceLevel: capitalize(
                            intl.formatMessage(
                              serviceLevelNameMap[selectedServiceLevel],
                            ),
                          ),
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="page.settings.view.savingsMode.information.more"
                        defaultMessage="More about {serviceLevel} mode"
                        values={{
                          serviceLevel: capitalize(
                            intl.formatMessage(
                              serviceLevelNameMap[selectedServiceLevel],
                            ),
                          ),
                        }}
                      />
                    )}
                  </GlowText>
                </GlowFlexbox>
              </GlowFlexbox>
            </button>
          </GlowFlexbox>
        </GlowCard>

        <GlowCard>
          <GlowFlexbox direction="column" gap="6">
            <GlowFlexbox direction="column" gap="2">
              <GlowText fontWeight="bold">
                <FormattedMessage
                  id="page.settings.view.savingsMode.updateManagedServicesTitle"
                  defaultMessage="Managed services"
                />
              </GlowText>

              {serviceLevel === "DO_EVERYTHING_MYSELF" && (
                <GlowFlexbox gap="2">
                  <GlowIcon
                    name="alert_circle_bold"
                    className="h-5 w-auto text-gray-300"
                  />
                  <GlowText>
                    <FormattedMessage
                      id="page.settings.view.savingsMode.updateManagedServicesManualAlert"
                      defaultMessage="We won't secure savings for you in Manual mode"
                    />
                  </GlowText>
                </GlowFlexbox>
              )}

              {serviceLevel !== "DO_EVERYTHING_MYSELF" && (
                <GlowText>
                  {intl.formatMessage<React.ReactNode>(
                    {
                      id: "page.settings.view.savingsMode.updateManagedServicesSubtitle",
                      defaultMessage:
                        "We're securing savings for <bold>{numberOfManagedServices}</bold> services",
                    },
                    {
                      numberOfManagedServices: numberOfManagedServices,
                      bold: (chunks) => (
                        <GlowText fontWeight="bold">{chunks}</GlowText>
                      ),
                    },
                  )}
                </GlowText>
              )}
            </GlowFlexbox>

            <GlowFlexbox direction="column" gap="4">
              {Object.entries(serviceTypeManagementEnablement).map(
                ([key, value]) => (
                  <GlowFlexbox
                    key={key}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GlowFlexbox
                      gap="3"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <GlowFlexbox
                        as="span"
                        className="relative grid h-10 w-10 place-items-center rounded-full bg-gray-50"
                      >
                        <GlowIcon
                          name={
                            categoryManagementIconMap[
                              `${capitalize(key)}Management` as SupportedManagement
                            ]
                          }
                          className="h-5 w-5 text-gray-500"
                        />

                        <GlowIcon
                          name="check_circle_1_bold"
                          className={clsx(
                            "absolute right-0 top-0 h-3 w-3",
                            value ? "text-virtuous-green-400" : "text-gray-200",
                          )}
                        />
                      </GlowFlexbox>

                      <GlowText size="sm">
                        {
                          categoryManagementNameMap[
                            `${capitalize(key)}Management` as SupportedManagement
                          ]
                        }
                      </GlowText>
                    </GlowFlexbox>

                    <GlowToggle
                      isDisabled={serviceLevel === "DO_EVERYTHING_MYSELF"}
                      onToggle={() => {
                        track([
                          "ServiceLineManagement",
                          "Edited",
                          {
                            value: value ? "OFF" : "ON",
                            category:
                              categoryManagementNameMap[
                                `${capitalize(key)}Management` as SupportedManagement
                              ],
                          },
                        ])
                        setServiceTypeManagementEnablement((current) => ({
                          ...current,
                          [key]: !value,
                        }))
                      }}
                      isChecked={value}
                    />
                  </GlowFlexbox>
                ),
              )}
            </GlowFlexbox>

            <GlowButton
              variant="primary"
              label={intl.formatMessage({
                id: "page.settings.view.savingsMode.updateManagedServicesButton",
                defaultMessage: "Save",
              })}
              isDisabled={
                serviceLevel === "DO_EVERYTHING_MYSELF" || changingServiceLevel
              }
              isLoading={creatingServiceTypeManagements}
              onClick={() =>
                createServiceTypeManagements({
                  variables: {
                    input: {
                      ...serviceTypeManagementEnablement,
                      context: "SETTINGS_PAGE",
                    },
                  },
                })
              }
            />
          </GlowFlexbox>
        </GlowCard>
      </GlowFlexbox>

      <ChangeServiceLevelPopup
        isOpen={changeServiceLevelPopup.isOpen}
        onConfirm={() =>
          updateHousehold({
            onCompleted: (data) => {
              if (
                data.updateHousehold.household?.serviceLevel ===
                "DO_EVERYTHING_MYSELF"
              ) {
                return handleManualConfirmed()
              }

              return changeServiceLevelPopup.close()
            },
            variables: {
              id: household.id,
              serviceLeveL: selectedServiceLevel,
            },
          })
        }
        onCancel={changeServiceLevelPopup.close}
        onClose={changeServiceLevelPopup.close}
        serviceLevel={selectedServiceLevel}
        changingServiceLevel={changingServiceLevel}
      />
    </>
  )
}

const ChangeServiceLevelPopup = (props: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
  serviceLevel: CheckedRelayEnum<NousServiceLevel>
  changingServiceLevel: boolean
}) => {
  const intl = useIntl()

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={intl.formatMessage(
        {
          id: "page.settings.view.savingsMode.changeServiceLevelPopup.title",
          defaultMessage: "Switch to {serviceLevel} mode?",
        },
        {
          serviceLevel: capitalize(
            intl.formatMessage(serviceLevelNameMap[props.serviceLevel]),
          ),
        },
      )}
      disableManualClose={props.changingServiceLevel}
    >
      <GlowFlexbox direction="column" gap="4" alignItems="center">
        <GlowText textAlign="center">
          <FormattedMessage
            {...serviceLevelChangeExplainationMap[props.serviceLevel]}
          />
        </GlowText>

        <GlowButton
          variant="primary"
          label={intl.formatMessage(
            {
              id: "page.settings.view.savingsMode.changeServiceLevelPopup.confirmButton",
              defaultMessage: "-> Switch to {serviceLevel} mode",
            },
            {
              serviceLevel: capitalize(
                intl.formatMessage(serviceLevelNameMap[props.serviceLevel]),
              ),
            },
          )}
          onClick={props.onConfirm}
          className="w-full"
          isLoading={props.changingServiceLevel}
          isDisabled={props.changingServiceLevel}
        />

        <GlowButton
          variant="secondary"
          label={intl.formatMessage({
            id: "page.settings.view.savingsMode.changeServiceLevelPopup.cancelButton",
            defaultMessage: "Close",
          })}
          onClick={props.onCancel}
          className="w-full"
          isLoading={props.changingServiceLevel}
          isDisabled={props.changingServiceLevel}
        />
      </GlowFlexbox>
    </GlowModal>
  )
}

export const serviceLevelChangeExplainationMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  MessageDescriptor
> = {
  DO_EVERYTHING_MYSELF: defineMessage({
    id: "page.settings.view.savingsMode.changeServiceLevelPopup.DO_EVERYTHING_MYSELF",
    defaultMessage:
      "On Manual mode we won't deal with the hassle of switching providers for you and you’ll forfeit cash rewards and access to exclusive deals.",
  }),
  DO_MOST_OF_IT_FOR_ME: defineMessage({
    id: "page.settings.view.savingsMode.changeServiceLevelPopup.DO_MOST_OF_IT_FOR_ME",
    defaultMessage:
      "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
  }),
  DO_ALL_OF_IT_FOR_ME: defineMessage({
    id: "page.settings.view.savingsMode.changeServiceLevelPopup.DO_ALL_OF_IT_FOR_ME",
    defaultMessage:
      "If you'd like us to find you savings and handle the hassle of switching for you then you must set your savings mode to Balanced or Freedom.",
  }),
}
