import clsx from "clsx"

import { GlowFlexbox, GlowBadge, GlowText } from "src/glow"
import { useViewport, Viewport } from "src/hooks/responsive"

export const ServiceLevelSelectButton = (props: {
  isSelected: boolean
  onSelect: () => void
  image: string
  label: string
  text: string
}) => {
  const viewport = useViewport()

  const isMobile = viewport <= Viewport.SM

  return (
    <button
      onClick={props.onSelect}
      className={clsx(
        "shadow-0 relative w-1/3 rounded-lg border-2 transition active:drop-shadow-none",
        isMobile && "w-full min-w-[176px] snap-center",
        props.isSelected
          ? "border-virtuous-green-400 bg-virtuous-green-100"
          : "border-gray-500/20 bg-white",
        props.isSelected
          ? "hover:border-virtuous-green-500 hover:drop-shadow-[2px_2px_0px_#26513B]"
          : "hover:border-gray-500 hover:drop-shadow-[2px_2px_0px_#1C1917]",
      )}
    >
      <GlowFlexbox
        direction="column"
        alignItems="center"
        justifyContent="center"
        padding={{
          x: "4",
          y: "4",
        }}
        gap="3"
      >
        <img
          src={props.image}
          alt={props.label}
          className="h-12 w-auto md:h-20"
        />
        <GlowFlexbox
          direction="column"
          gap="1"
          alignItems="center"
          justifyContent="center"
        >
          <GlowBadge
            variant={
              props.isSelected ? "white" : isMobile ? "default" : "white"
            }
            label={props.label}
            size="sm"
          />
          <GlowText
            fontWeight="medium"
            isDisplay
            className="text-gray-500"
            textAlign="center"
            size="sm"
          >
            {props.text}
          </GlowText>
        </GlowFlexbox>
      </GlowFlexbox>
      {props.isSelected && (
        <div className="bg-virtuous-green-400 absolute right-1.5 top-1.5 h-2 w-2 rounded-full" />
      )}
    </button>
  )
}
