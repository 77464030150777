/**
 * @generated SignedSource<<9e10313e8d063f2635988224fd991f99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type YourHouseholdPageQuery$variables = Record<PropertyKey, never>;
export type YourHouseholdPageQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"YourHouseholdDetails_currentUser">;
  } | null | undefined;
  readonly household: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DeleteMyAccount_household" | "YourHouseholdDetails_household" | "useRedirectToWaitlist_household">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BankAccounts_query">;
};
export type YourHouseholdPageQuery = {
  response: YourHouseholdPageQuery$data;
  variables: YourHouseholdPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sortCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "iban",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Bank",
    "kind": "LinkedField",
    "name": "bank",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isManaged",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "YourHouseholdPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BankAccounts_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "YourHouseholdDetails_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeleteMyAccount_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useRedirectToWaitlist_household"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "YourHouseholdDetails_currentUser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "YourHouseholdPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OpenBankingBankAccount",
        "kind": "LinkedField",
        "name": "myOpenBankingBankAccounts",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OpenBankingBankAccount",
        "kind": "LinkedField",
        "name": "otherOpenBankingBankAccounts",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstLine",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postcode",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdMemberInvite",
            "kind": "LinkedField",
            "name": "pendingInvites",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdMember",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BroadbandServiceSummary",
            "kind": "LinkedField",
            "name": "broadbandServiceSummary",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EnergyServiceSummary",
            "kind": "LinkedField",
            "name": "energyServiceSummary",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MobileServiceSummary",
            "kind": "LinkedField",
            "name": "mobileServiceSummaries",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inWaitlist",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDelegated",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed5214a6d68ea2cf895425deb599a4db",
    "id": null,
    "metadata": {},
    "name": "YourHouseholdPageQuery",
    "operationKind": "query",
    "text": "query YourHouseholdPageQuery {\n  ...BankAccounts_query\n  household {\n    id\n    ...YourHouseholdDetails_household\n    ...DeleteMyAccount_household\n    ...useRedirectToWaitlist_household\n  }\n  currentUser {\n    ...YourHouseholdDetails_currentUser\n    id\n  }\n}\n\nfragment Avatar_invite on HouseholdMemberInvite {\n  initials\n  firstName\n}\n\nfragment Avatar_user on User {\n  initials\n  firstName\n}\n\nfragment BankAccounts_query on Query {\n  ...MyOpenBankingBankAccounts_query\n  ...OtherOpenBankingBankAccounts_query\n}\n\nfragment CreateAccountPopup_address on HouseholdAddress {\n  firstLine\n}\n\nfragment DeleteMyAccount_household on Household {\n  broadbandServiceSummary {\n    isManaged\n    id\n  }\n  energyServiceSummary {\n    isManaged\n    id\n  }\n  mobileServiceSummaries {\n    isManaged\n    id\n  }\n}\n\nfragment MyOpenBankingBankAccounts_query on Query {\n  myOpenBankingBankAccounts {\n    id\n    name\n    sortCode\n    accountNumber\n    iban\n    bank {\n      id\n      name\n      logo\n    }\n  }\n}\n\nfragment OtherOpenBankingBankAccounts_query on Query {\n  otherOpenBankingBankAccounts {\n    id\n    name\n    sortCode\n    accountNumber\n    iban\n    bank {\n      id\n      name\n      logo\n    }\n  }\n}\n\nfragment ShareInvite_currentUser on User {\n  firstName\n}\n\nfragment YourHouseholdDetails_currentUser on User {\n  ...ShareInvite_currentUser\n}\n\nfragment YourHouseholdDetails_household on Household {\n  id\n  address {\n    firstLine\n    postcode\n    ...CreateAccountPopup_address\n  }\n  ...YourHouseholdMemberAvatars_household\n}\n\nfragment YourHouseholdMemberAvatars_household on Household {\n  pendingInvites {\n    id\n    ...Avatar_invite\n  }\n  members {\n    id\n    user {\n      ...Avatar_user\n      id\n    }\n  }\n}\n\nfragment useRedirectToWaitlist_household on Household {\n  inWaitlist\n  serviceLevel\n  isDelegated\n}\n"
  }
};
})();

(node as any).hash = "4a822efc433c5cb55d2c2f1a2f427eba";

export default node;
