/**
 * @generated SignedSource<<9a05e2bfdde5af312eeaeba46005cfaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageSubPageBankAccounts_household$data = {
  readonly bankAccounts: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BankAccount" | "BankAccountAddOrEditPopup_bankAccount">;
  }>;
  readonly " $fragmentType": "SettingsPageSubPageBankAccounts_household";
};
export type SettingsPageSubPageBankAccounts_household$key = {
  readonly " $data"?: SettingsPageSubPageBankAccounts_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageSubPageBankAccounts_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageSubPageBankAccounts_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BankAccount"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BankAccountAddOrEditPopup_bankAccount"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "054cd7e043fd18c5c74331d9b43f08f5";

export default node;
