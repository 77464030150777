/**
 * @generated SignedSource<<2a0169d988e6a29ad7084b2288f1e27f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectBankPageQuery$variables = Record<PropertyKey, never>;
export type ConnectBankPageQuery$data = {
  readonly household: {
    readonly address: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateAccountPopup_address">;
    } | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UnselectedBankContent_query">;
};
export type ConnectBankPageQuery = {
  response: ConnectBankPageQuery$data;
  variables: ConnectBankPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectBankPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UnselectedBankContent_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateAccountPopup_address"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConnectBankPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bank",
        "kind": "LinkedField",
        "name": "tinkBanks",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstLine",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3c121d26bfada5776c5bdb5862c74eeb",
    "id": null,
    "metadata": {},
    "name": "ConnectBankPageQuery",
    "operationKind": "query",
    "text": "query ConnectBankPageQuery {\n  ...UnselectedBankContent_query\n  household {\n    address {\n      ...CreateAccountPopup_address\n    }\n    id\n  }\n}\n\nfragment BankList_query on Query {\n  tinkBanks {\n    id\n    name\n    logo\n  }\n}\n\nfragment CreateAccountPopup_address on HouseholdAddress {\n  firstLine\n}\n\nfragment UnselectedBankContent_query on Query {\n  ...BankList_query\n}\n"
  }
};
})();

(node as any).hash = "eeb9edf953b8988a4fbc0ee093fd018b";

export default node;
