import { useIntl } from "react-intl"

import { SettingsPageOverviewGenericSection } from "./SettingsPageOverviewGenericSection"

export const SettingsPageOverviewNotifications = (props: {
  onGoToNotificationsView: () => void
}) => {
  const intl = useIntl()

  return (
    <SettingsPageOverviewGenericSection
      icon={"settings_slider_regular"}
      title={intl.formatMessage({
        id: "page.settings.view.overview.notificationsTitle",
        defaultMessage: "Notification preferences",
      })}
      onClick={props.onGoToNotificationsView}
    />
  )
}
