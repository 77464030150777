import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import gmail from "src/assets/gmail.png"
import gmail2x from "src/assets/gmail@2x.png"
import gmail3x from "src/assets/gmail@3x.png"
import microsoft from "src/assets/microsoft.png"
import microsoft2x from "src/assets/microsoft@2x.png"
import microsoft3x from "src/assets/microsoft@3x.png"
import { ConnectEmailDisclaimer } from "src/components/ConnectEmailDisclaimer/ConnectEmailDisclaimer"
import ConnectWithGmailPopup from "src/components/ConnectWithGmailPopup/ConnectWithGmailPopup"
import { ConnectWithOutlookPopup } from "src/components/ConnectWithOutlookPopup/ConnectWithOutlookPopup"
import { PoweredByAi } from "src/components/PoweredByAi/PoweredByAi"
import { useCheckGate } from "src/Gate"
import { GlowButton, GlowFlexbox, GlowIcon, GlowLink, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { useModal, usePopup } from "src/hooks/modal"
import { MicrosoftConnectionCallback } from "src/hooks/useMicrosoftOutlook"
import OnboardingContent from "src/pages/Onboarding/components/OnboardingContent"
import { useOrchestrator } from "src/pages/Onboarding/components/OnboardingOrchestrator"
import { OnboardingSkipReasonModal } from "src/pages/Onboarding/components/OnboardingSkipReasonModal"
import useTracking from "src/tracking/useTracking"
import useTrackingLegacy from "src/tracking/useTracking_LEGACY"

import { OnboardingConnectEmailStepInitialContent_formAnswer$key } from "./__generated__/OnboardingConnectEmailStepInitialContent_formAnswer.graphql"

export const skipReasons = defineMessages({
  doNotUse: {
    id: "onboarding.connectEmailStepContentEmail.skipReasons.doNotUse",
    defaultMessage: "I don't use Gmail or Outlook",
  },
  notComfortable: {
    id: "onboarding.connectEmailStepContentEmail.skipReasons.notComfortable",
    defaultMessage: "I don't feel comfortable connecting my email",
  },
  noBills: {
    id: "onboarding.connectEmailStepContentEmail.skipReasons.noBills",
    defaultMessage: "My bills are not in a Gmail or Outlook account",
  },
  other: {
    id: "onboarding.connectEmailStepContentEmail.skipReasons.other",
    defaultMessage: "Other",
  },
})

export default function OnboardingConnectEmailStepInitialContent(props: {
  onGmailConnection: (authorizationCode: string) => void
  onOutlookConnection: MicrosoftConnectionCallback
  skipReasonFormAnswer: OnboardingConnectEmailStepInitialContent_formAnswer$key | null
  hideSkipButton?: boolean
}) {
  const trackLegacy = useTrackingLegacy()
  const track = useTracking()
  const { onNextStep } = useOrchestrator()
  const intl = useIntl()
  const gmailPopup = usePopup()
  const outlookPopup = usePopup()
  const checkGate = useCheckGate()
  const areYouSureYouWantToSkipModal = useModal()
  const skipReasonModal = useModal()

  const skipReasonFormAnswer = useFragment(
    graphql`
      fragment OnboardingConnectEmailStepInitialContent_formAnswer on FormAnswer {
        ...OnboardingSkipReasonModal_formAnswer
      }
    `,
    props.skipReasonFormAnswer,
  )

  return (
    <OnboardingContent noHeader>
      <GlowFlexbox direction="column" gap="6">
        <GlowFlexbox gap="4" justifyContent="center" alignItems="center">
          <div className="border-black-12 grid h-12 w-12 place-items-center rounded-lg border">
            <GlowIcon name="email_action_unread_regular" className="h-8 w-8" />
          </div>
          <GlowIcon
            name="data_transfer_horizontal_regular"
            className="h-5 w-5 text-gray-200"
          />
          <div className="border-black-12 grid h-12 w-12 place-items-center rounded-lg border">
            <GlowIcon name="nous_logo" className="h-2 w-full" />
          </div>
        </GlowFlexbox>
        <GlowText textAlign="center">
          <FormattedMessage
            id="onboarding.connectEmailStepContent.connectEmail.title"
            defaultMessage="Connect your inbox and we’ll automatically forward bills to your Nous account."
          />
        </GlowText>
        <GlowFlexbox direction="column" gap="4">
          <GlowButton
            variant="primary"
            icon={
              <img
                srcSet={`${gmail}, ${gmail2x} 2x, ${gmail3x} 3x`}
                alt=""
                className="h-4 w-4"
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Connect a Gmail inbox",
              id: "onboarding.connectEmailStepContent.connectGmail",
            })}
            onClick={() => {
              track([
                "EmailConnection",
                "Attempted",
                { emailProvider: "Google" },
              ])
              gmailPopup.open()
            }}
          />
          <ConnectWithGmailPopup
            onConnect={(code) => {
              gmailPopup.close()
              props.onGmailConnection(code)
            }}
            isOpen={gmailPopup.isOpen}
            onClose={gmailPopup.close}
          />
          <GlowButton
            variant="primary"
            onClick={() => {
              track([
                "EmailConnection",
                "Attempted",
                { emailProvider: "Microsoft" },
              ])
              outlookPopup.open()
            }}
            icon={
              <img
                srcSet={`${microsoft}, ${microsoft2x} 2x, ${microsoft3x} 3x`}
                alt=""
                className="h-4 w-4"
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Connect an Outlook inbox",
              id: "onboarding.connectEmailStepContent.connectOutlook",
            })}
          />
          <ConnectWithOutlookPopup
            onClose={outlookPopup.close}
            isOpen={outlookPopup.isOpen}
            onConnect={props.onOutlookConnection}
          />
        </GlowFlexbox>
      </GlowFlexbox>
      {!props.hideSkipButton && (
        <>
          <GlowLink
            onClick={() => {
              if (checkGate("Onboarding_skip_reasons")) {
                areYouSureYouWantToSkipModal.open()
              } else {
                track(["EmailConnection", "Rejected", { reason: "Unknown" }])
                onNextStep()
              }
            }}
            label={intl.formatMessage({
              id: "onboarding.connectEmailStepContent.skip",
              defaultMessage: "Continue without connecting",
            })}
          />
          <AreYouSureYouWantToSkipModal
            isOpen={areYouSureYouWantToSkipModal.isOpen}
            onClose={areYouSureYouWantToSkipModal.close}
            onYesSkip={() => {
              trackLegacy("Email connection skip reason modal shown")
              areYouSureYouWantToSkipModal.close()
              skipReasonModal.open()
            }}
          />
          {skipReasonFormAnswer && (
            <OnboardingSkipReasonModal
              isOpen={skipReasonModal.isOpen}
              onClose={() => {
                skipReasonModal.close()
                areYouSureYouWantToSkipModal.open()
              }}
              reasons={Object.values(skipReasons).map((reason) =>
                intl.formatMessage(reason),
              )}
              onSelectReason={(reason) => {
                track(["EmailConnection", "Rejected", { reason }])
                onNextStep()
              }}
              formAnswer={skipReasonFormAnswer}
            />
          )}
        </>
      )}

      <ConnectEmailDisclaimer />

      <PoweredByAi />
    </OnboardingContent>
  )
}

const AreYouSureYouWantToSkipModal = (props: {
  isOpen: boolean
  onClose: () => void
  onYesSkip: () => void
}) => {
  const intl = useIntl()
  const track = useTrackingLegacy()

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={intl.formatMessage({
        id: "onboarding.connectEmailStepContent.skipAreYouSure.title",
        defaultMessage: "Are you sure you want to skip?",
      })}
      hideDrawerTitle
    >
      <GlowFlexbox direction="column" gap="6" alignItems="center">
        <GlowFlexbox direction="column" gap="3" alignItems="center">
          <GlowIcon name="email_action_unread_regular" className="h-8 w-8" />
          <GlowFlexbox direction="column" gap="3" alignItems="center">
            <GlowText size="lg" fontWeight="bold" textAlign="center">
              <FormattedMessage
                id="onboarding.connectEmailStepContent.skipAreYouSure.paragraph1"
                defaultMessage="Connecting your email is the simplest way to get set up."
              />
            </GlowText>
            <GlowText size="sm" textAlign="center">
              <FormattedMessage
                id="onboarding.connectEmailStepContent.skipAreYouSure.paragraph2"
                defaultMessage="80% of people choose to connect their email to share info about their providers with Nous."
              />
            </GlowText>
          </GlowFlexbox>
        </GlowFlexbox>
        <GlowButton
          variant="primary"
          label={intl.formatMessage({
            id: "onboarding.connectEmailStepContent.skipAreYouSure.npCheck",
            defaultMessage: "OK, let's try it",
          })}
          onClick={() => {
            track("User thought twice about skipping email connection")
            props.onClose()
          }}
          className="w-full"
        />
        <GlowLink
          onClick={() => {
            track("User is sure they want to skip email connection")
            props.onYesSkip()
          }}
          label={intl.formatMessage({
            id: "onboarding.connectEmailStepContent.skipAreYouSure.yesSkip",
            defaultMessage: "I still want to skip",
          })}
        />
      </GlowFlexbox>
    </GlowModal>
  )
}
