import { format } from "date-fns"
import Dinero from "dinero.js"
import { MessageDescriptor, defineMessage } from "react-intl"
import { UseMutationConfig } from "react-relay"
import { Disposable, MutationParameters } from "relay-runtime"

import ImageHammock from "src/assets/Hammock.svg"
import ImageHighFive from "src/assets/HighFive.svg"
import ImagePuzzleWoman from "src/assets/PuzzleWoman.svg"
import { logger } from "src/logger"

import { GlowIconName } from "./glow/GlowIcon"
import { NousServiceLevel } from "./hooks/__generated__/useRedirectToWaitlist_household.graphql"
import { SavingsQuest, savingsQuests } from "./types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function match<T, K extends keyof any>(k: K, cases: { [P in K]: T }): T {
  return cases[k]
}

export function minBy<T>(
  elements: T[],
  fn: (element: T) => number,
): T | undefined {
  const mapped = elements.map(fn)
  const min = Math.min(...mapped)
  if (isFinite(min)) {
    return elements[mapped.indexOf(min)]
  }
}

export function maxBy<T>(
  elements: T[],
  fn: (element: T) => number,
): T | undefined {
  const mapped = elements.map(fn)
  const max = Math.max(...mapped)
  if (isFinite(max)) {
    return elements[mapped.indexOf(max)]
  }
}

type PartitionReturn<T> = { true: T[]; false: T[] }

export function partition<T>(
  elements: T[],
  predicate: (element: T) => boolean,
): PartitionReturn<T> {
  const out: PartitionReturn<T> = { true: [], false: [] }
  for (const element of elements) {
    if (predicate(element)) {
      out.true.push(element)
    } else {
      out.false.push(element)
    }
  }
  return out
}

export function formatDateTime(dateString: string, fmt = "MMM yy") {
  // `new Date` could bork here, don't let it crash the whole app!
  try {
    return format(new Date(dateString), fmt)
  } catch (error) {
    logger.error("Error formatting datetime", {
      error,
      dateString,
      format,
    })
    return ""
  }
}

export function getOutcodeFromPostcode(postcode: string): string {
  return postcode.replace(" ", "").slice(0, -3)
}

export const getCalendarMonth = (date: Date) => {
  // month is indexed from 0
  return date.getMonth() + 1
}

export const formatMoneyAmount = ({
  amount,
  precision,
  currency,
}: {
  amount: number
  precision?: number
  currency?: Dinero.Currency
}) => {
  return Dinero({
    amount,
    precision,
    currency,
  }).toFormat("$0,0")
}

export function filterNulls<T>(input: Array<T | null | undefined>): T[] {
  return input.filter(Boolean) as T[]
}
export function filterNullsAndFalse<T>(
  input: Array<T | null | undefined | false>,
): T[] {
  return input.filter(Boolean) as T[]
}

/**
 * Truncate a string of text to a given length with a word boundary.
 * @example
 * ```
 * truncate("Hello world", 5)
 * Returns: "Hello..."
 * ```
 * @example
 * ```
 * truncate("Hello world", 6, false)
 * Returns: "Hello ..."
 * ```
 */
export const truncate = (
  str: string,
  maxLength = 20,
  withWordBoundary = true,
) => {
  if (str.length <= maxLength) {
    return str
  }
  const subString = str.slice(0, maxLength)
  return `${
    withWordBoundary && subString.lastIndexOf(" ") !== -1
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString
  }\u2026`
}

export type CheckedRelayEnum<T extends string> = Exclude<
  T,
  "%future added value"
>

export function handleFutureValueOnRelayEnum<T extends string>(
  value: T | null | undefined,
  defaultValue: CheckedRelayEnum<T>,
): CheckedRelayEnum<T> {
  return (
    !value || value === "%future added value" ? defaultValue : value
  ) as CheckedRelayEnum<T>
}

export function handleFutureValueOnRelayEnumOrNull<T extends string>(
  value: T | null | undefined,
  defaultValue: CheckedRelayEnum<T> | null,
): CheckedRelayEnum<T> | null {
  return (
    !value || value === "%future added value" ? defaultValue : value
  ) as CheckedRelayEnum<T>
}

export function relayMutationToPromise<TMutation extends MutationParameters>(
  mutationFn: (config: UseMutationConfig<TMutation>) => Disposable,
  config: UseMutationConfig<TMutation>,
): Promise<TMutation["response"]> {
  return new Promise((resolve, reject) => {
    mutationFn({
      ...config,
      onCompleted(response, errors) {
        config.onCompleted?.(response, errors)

        if (errors && errors.length) {
          reject(errors)
        }

        resolve(response)
      },
      onError(...args) {
        config.onError?.(...args)
        reject(args[0])
      },
    })
  })
}

export function first<T>(array: ReadonlyArray<T>): T | undefined {
  return array[0]
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function timeoutPromise<T>({
  promise,
  timeout = 5000,
  onTimeout,
}: {
  promise: Promise<T>
  timeout?: number
  onTimeout?: () => void
}): Promise<T | null> {
  let resolved = false

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (!resolved) {
        resolved = true
        onTimeout?.()
        resolve(null)
      }
    }, timeout)

    promise.then(
      (value) => {
        if (!resolved) {
          resolved = true
          clearTimeout(timeoutId)
          resolve(value)
        }
      },
      (error) => {
        if (!resolved) {
          resolved = true
          clearTimeout(timeoutId)
          reject(error)
        }
      },
    )
  })
}

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max))

export const mapSavingsQuestToBadge: Record<
  SavingsQuest,
  { iconName: GlowIconName; label: MessageDescriptor }
> = {
  BroadbandSavingsQuest: {
    iconName: "wifi_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.broadbandSavingsQuest",
      defaultMessage: "Broadband savings",
    }),
  },
  EnergySavingsQuest: {
    iconName: "flash_1_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.energySavingsQuest",
      defaultMessage: "Energy savings",
    }),
  },
  MobileSavingsQuest: {
    iconName: "mobile_phone_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.mobileSavingsQuest",
      defaultMessage: "Mobile savings",
    }),
  },
  MortgageSavingsQuest: {
    iconName: "real_estate_insurance_house_regular",
    label: defineMessage({
      id: "quests.savingsQuests.badge.mortgageSavingsQuest",
      defaultMessage: "Mortgage savings",
    }),
  },
}

export function isSavingsQuest<T extends { __typename: string }>(
  quest: T,
): quest is T & { __typename: SavingsQuest } {
  return (savingsQuests as Array<string>).includes(quest.__typename)
}

export const serviceLevelNameMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  MessageDescriptor
> = {
  DO_EVERYTHING_MYSELF: defineMessage({
    id: "utils.serviceLevelName.DO_EVERYTHING_MYSELF",
    defaultMessage: "MANUAL",
  }),
  DO_MOST_OF_IT_FOR_ME: defineMessage({
    id: "utils.serviceLevelName.DO_MOST_OF_IT_FOR_ME",
    defaultMessage: "BALANCED",
  }),
  DO_ALL_OF_IT_FOR_ME: defineMessage({
    id: "utils.serviceLevelName.DO_ALL_OF_IT_FOR_ME",
    defaultMessage: "FREEDOM",
  }),
}

export const serviceLevelImageMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  string
> = {
  DO_EVERYTHING_MYSELF: ImagePuzzleWoman,
  DO_MOST_OF_IT_FOR_ME: ImageHighFive,
  DO_ALL_OF_IT_FOR_ME: ImageHammock,
}

export const serviceLevelDescriptionMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  {
    header: MessageDescriptor
    paragraph: MessageDescriptor
  }
> = {
  DO_EVERYTHING_MYSELF: {
    header: defineMessage({
      id: "utils.serviceLevelDescription.header.DO_EVERYTHING_MYSELF",
      defaultMessage:
        "We'll flag possible savings but you'll have to sort all the details yourself.",
    }),
    paragraph: defineMessage({
      id: "utils.serviceLevelDescription.paragraph.DO_EVERYTHING_MYSELF",
      defaultMessage:
        "The ideal choice if you prefer to do the work yourself. You may miss out on Nous cash rewards.",
    }),
  },
  DO_MOST_OF_IT_FOR_ME: {
    header: defineMessage({
      id: "utils.serviceLevelDescription.header.DO_MOST_OF_IT_FOR_ME",
      defaultMessage:
        "We'll find you savings and automatically handle the hassle of switching providers for you.",
    }),
    paragraph: defineMessage({
      id: "utils.serviceLevelDescription.paragraph.DO_MOST_OF_IT_FOR_ME",
      defaultMessage:
        "The ideal choice for busy professionals and families, offering a balance of convenience and control.",
    }),
  },
  DO_ALL_OF_IT_FOR_ME: {
    header: defineMessage({
      id: "utils.serviceLevelDescription.header.DO_ALL_OF_IT_FOR_ME",
      defaultMessage:
        "We'll act on your behalf to find savings automatically and try not to bother you.",
    }),
    paragraph: defineMessage({
      id: "utils.serviceLevelDescription.paragraph.DO_ALL_OF_IT_FOR_ME",
      defaultMessage:
        "The ideal choice for landlords and serviced apartments. Delegate all decisions over household bills to Nous.",
    }),
  },
}

export const serviceLevelBenefitsMap: Record<
  CheckedRelayEnum<NousServiceLevel>,
  Array<{
    icon: GlowIconName
    text: MessageDescriptor
    missed?: boolean
  }>
> = {
  DO_EVERYTHING_MYSELF: [
    {
      icon: "check_double_1_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_EVERYTHING_MYSELF.1",
        defaultMessage:
          "We'll only ever propose like-for-like deals from trusted providers",
      }),
    },
    {
      icon: "close_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_EVERYTHING_MYSELF.2",
        defaultMessage: "You deal with the hassle of switching providers",
      }),
      missed: true,
    },
    {
      icon: "close_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_EVERYTHING_MYSELF.3",
        defaultMessage: "Forfeit cash rewards and access to exclusive deals",
      }),
      missed: true,
    },
  ],
  DO_MOST_OF_IT_FOR_ME: [
    {
      icon: "check_double_1_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_MOST_OF_IT_FOR_ME.1",
        defaultMessage:
          "We'll only ever propose like-for-like deals from trusted providers",
      }),
    },
    {
      icon: "message_bubble_question_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_MOST_OF_IT_FOR_ME.2",
        defaultMessage:
          "We'll give you plenty of time to review our proposals before we make changes",
      }),
    },
    {
      icon: "task_finger_show_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_MOST_OF_IT_FOR_ME.3",
        defaultMessage:
          "You remain in control and can opt-out of proposed changes at any time",
      }),
    },
  ],
  DO_ALL_OF_IT_FOR_ME: [
    {
      icon: "check_double_1_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_ALL_OF_IT_FOR_ME.1",
        defaultMessage:
          "We'll only ever propose like-for-like deals from trusted providers",
      }),
    },
    {
      icon: "time_rest_time_3_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_ALL_OF_IT_FOR_ME.2",
        defaultMessage:
          "We'll automatically switch your providers, letting you know once it's done",
      }),
    },
    {
      icon: "synchronize_refresh_arrow_2_regular",
      text: defineMessage({
        id: "utils.serviceLevelBenefit.DO_ALL_OF_IT_FOR_ME.3",
        defaultMessage:
          "We'll reverse any changes we've made if you're unhappy with them",
      }),
    },
  ],
}

type SupportedCategory = "Broadband" | "Energy" | "Mobile" | "Mortgage"
export type SupportedManagement = `${SupportedCategory}Management`

export const categoryManagementIconMap: Record<
  SupportedManagement,
  GlowIconName
> = {
  BroadbandManagement: "wifi_regular",
  EnergyManagement: "flash_1_regular",
  MobileManagement: "mobile_phone_regular",
  MortgageManagement: "real_estate_insurance_house_regular",
}

export const categoryManagementNameMap: Record<
  SupportedManagement,
  SupportedCategory
> = {
  BroadbandManagement: "Broadband",
  EnergyManagement: "Energy",
  MobileManagement: "Mobile",
  MortgageManagement: "Mortgage",
}
