import { FormattedMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import clockIllustrationSereneBlue from "src/assets/clockIllustrationSereneBlue.png"
import clockIllustrationSereneBlue2x from "src/assets/clockIllustrationSereneBlue@2x.png"
import clockIllustrationSereneBlue3x from "src/assets/clockIllustrationSereneBlue@3x.png"
import { ConnectEmailDisclaimer } from "src/components/ConnectEmailDisclaimer/ConnectEmailDisclaimer"
import { PoweredByAi } from "src/components/PoweredByAi/PoweredByAi"
import { GlowFlexbox, GlowButton, GlowText, GlowCard, GlowLink } from "src/glow"
import OnboardingContent from "src/pages/Onboarding/components/OnboardingContent"

import { OnboardingConnectEmailBenefitsStepContent_household$key } from "./__generated__/OnboardingConnectEmailBenefitsStepContent_household.graphql"
import { OnboardingConnectEmailBenefitsStepConnectedEmailsSection } from "./OnboardingConnectEmailBenefitsStepConnectedEmailsSection"

type Props = {
  household: OnboardingConnectEmailBenefitsStepContent_household$key | null
  hideBackButton?: boolean
  onConnectEmail?: () => void
  hideSkipButton?: boolean
  onNextStep?: (value: boolean) => void
}

export function OnboardingConnectEmailBenefitsStepContent(props: Props) {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment OnboardingConnectEmailBenefitsStepContent_household on Household {
        formAnswers {
          connectEmailBenefits: answer(key: "connectEmailBenefits") {
            key @required(action: THROW)
            id @required(action: THROW)
            booleanValue
          }
        }
        emailConnections {
          ...OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections
        }
      }
    `,
    props.household,
  )

  return (
    <OnboardingContent noHeader>
      <GlowFlexbox direction="column" alignItems="center" gap="6">
        <GlowFlexbox direction="column" alignItems="center" gap="4">
          <img
            srcSet={`${clockIllustrationSereneBlue}, ${clockIllustrationSereneBlue2x} 2x, ${clockIllustrationSereneBlue3x} 3x`}
            alt=""
            className="w-24"
          />
          <GlowText fontWeight="medium" textAlign="center">
            <FormattedMessage
              id="onboarding.connectEmailBenefits.header"
              defaultMessage="Save time with Nous AI - connect email inboxes and we’ll automatically find bills and contracts sent by your providers."
            />
          </GlowText>
        </GlowFlexbox>

        {household && household.emailConnections.length === 0 ? (
          <GlowCard colour="transparent" className="w-full">
            <GlowFlexbox direction="column" alignItems="center" gap="4">
              <GlowText textAlign="center">
                <FormattedMessage
                  id="onboarding.connectEmailBenefits.benefits.helpText"
                  defaultMessage="Connect an inbox to get started"
                />
              </GlowText>
              <GlowButton
                onClick={() =>
                  props.onConnectEmail?.() ?? props.onNextStep?.(true)
                }
                variant="primary"
                label={intl.formatMessage({
                  id: "onboarding.connectEmailBenefits.cta.connect",
                  defaultMessage: "Connect an inbox",
                })}
                iconName="add_circle_bold"
                className="w-full"
              />
            </GlowFlexbox>
          </GlowCard>
        ) : (
          <OnboardingConnectEmailBenefitsStepConnectedEmailsSection
            emailConnections={household?.emailConnections ?? null}
            onConnectEmail={() =>
              props.onConnectEmail?.() ?? props.onNextStep?.(true)
            }
          />
        )}

        {!props.hideSkipButton && (
          <GlowLink
            onClick={() => props.onNextStep?.(false)}
            label={intl.formatMessage({
              id: "onboarding.connectEmailBenefits.cta.skip",
              defaultMessage: "Skip for now",
            })}
          />
        )}

        <ConnectEmailDisclaimer />

        <PoweredByAi />
      </GlowFlexbox>
    </OnboardingContent>
  )
}
